'use strict'

import { Vector2, Raycaster, Frustum, Matrix4 } from 'three'

let DragControls = function (scene, camera, domElement) {
    if (domElement === document)
        console.error(
            'PlayerControls: "document" should not be used as the target "domElement". Please use "renderer.domElement" instead.'
        )

    this.domElement = domElement
    this.camera = camera
    this.scene = scene
    this.enabled = true
    this.movingEnabled = true //raycasting bleibt hier dennoch enabled
    let scope = this

    //DRAG CONTROLS
    // eslint-disable-next-line no-unused-vars
    let dragging = false
    let windowSize = new Vector2(scope.domElement.offsetWidth, scope.domElement.offsetHeight)
    let mouse = new Vector2()
    const scale = new Vector2(0.2, 0.16)
    let destination = new Vector2()
    let dx, dy

    //RAYCASTING
    const raycaster = new Raycaster()
    let collage = scope.scene.getObjectByName('collage')
    let start = new Vector2()
    let delta = 4

    //INTRO BOOLEANS
    let movedInIntro = false
    let navToFlag = false
    let flag = collage.getObjectByName('flagge_haeger')

    //LOGISTIC MAPPING
    //max is x: 0.1, y:0.05 with minus
    function logistic(x) {
        return 0.25 / (1 + Math.exp(-30 * x)) - 0.1
    }

    function onPointerDown(e) {
        if (scope.enabled === false) return

        mouse.set(e.clientX, e.clientY)
        start.set(e.clientX, e.clientY)

        scope.domElement.addEventListener('pointermove', onPointerMove, false)
        scope.domElement.addEventListener('pointerup', onPointerUp, false)
        scope.domElement.addEventListener('pointerleave', onPointerUp, false)

        scope.domElement.oncontextmenu = function (event) {
            event.preventDefault()
        }

        scope.domElement.addEventListener('touchstart', (e) => e.preventDefault())

        dragging = true

        let x = windowSize.x / 2
        let y = windowSize.y / 2
        //let x = e.clientX;
        //let y = e.clientY;

        dx = ((x - mouse.x) / windowSize.x) * -1
        dy = (y - mouse.y) / windowSize.y

        destination.x = logistic(dx * scale.x)
        destination.y = logistic(dy * scale.y) / 2

        //mouse.set(x, y);
    }

    function onPointerMove(e) {
        if (scope.enabled === false) return
        if (dragging === false) return

        e.preventDefault()

        mouse.set(e.clientX, e.clientY)

        let x = windowSize.x / 2
        let y = windowSize.y / 2
        //let x = e.clientX;
        //let y = e.clientY;

        dx = ((x - mouse.x) / windowSize.x) * -1
        dy = (y - mouse.y) / windowSize.y

        destination.x = logistic(dx * scale.x)
        destination.y = logistic(dy * scale.y) / 2
        //console.log(destination);
        //destination.x = dx * scale.x;
        //destination.y = dy * scale.y;

        //mouse.set(x, y);
    }

    function onPointerUp(e) {
        if (scope.enabled === false) return
        e.preventDefault()

        //check if drag or click
        //attention: when stopping same as starting, this is also true
        //TODO: solve that problem
        const diffX = Math.abs(mouse.x - start.x)
        const diffY = Math.abs(mouse.y - start.y)

        if (diffX < delta && diffY < delta) {
            // calculate mouse position in normalized device coordinates
            // (-1 to +1) for both components
            mouse.x = (e.clientX / windowSize.x) * 2 - 1
            mouse.y = -(e.clientY / windowSize.y) * 2 + 1
            raycast()
        }

        scope.domElement.removeEventListener('pointermove', onPointerMove, false)
        scope.domElement.removeEventListener('pointerup', onPointerUp, false)
        scope.domElement.removeEventListener('pointerleave', onPointerUp, false)
        scope.domElement.removeEventListener('touchstart', (e) => e.preventDefault())

        dragging = false

        if (scope.movingEnabled === false) return
        //reset for next element
        destination.set(0, 0)
    }

    function transformCam() {
        if (scope.enabled === false) return
        if (scope.movingEnabled === false) return

        camera.translateX(destination.x)
        camera.translateY(destination.y)

        //restrict movement
        if (camera.position.y <= 0.5) camera.position.y = 0.5
        else if (camera.position.y >= 20) camera.position.y = 20
        if (camera.position.x <= -22) camera.position.x = -22
        else if (camera.position.x >= 22) camera.position.x = 22
        if (camera.position.z <= -0.1) camera.position.z = -0.1
    }

    function raycast() {
        // update the picking ray with the camera and mouse position
        raycaster.setFromCamera(mouse, scope.camera)

        // calculate objects intersecting the picking ray
        const intersects = raycaster.intersectObjects(collage.children)

        if (intersects.length > 0) {
            //intersects[0].object.material.color.set( 0xff0000 );
            triggerDocElement(intersects[0].object.name)

            //TODO: check if click is in transparent areas of material
        }
    }

    //emit event to trigger the doc element
    function triggerDocElement(elemName) {
        const event = new CustomEvent('triggerDocElement', {
            bubbles: true,
            detail: {
                name: elemName,
            },
        })
        // console.log("emitting event to trigger doc elem", elemName);
        scope.domElement.dispatchEvent(event)
    }

    function triggerIntroEvent(task) {
        const event = new CustomEvent('introTaskDone', {
            bubbles: true,
            detail: {
                task: task,
            },
        })
        scope.domElement.dispatchEvent(event)
    }

    //window resize
    this.handleResize = function (width, height) {
        windowSize.x = width
        windowSize.y = height
    }

    //connect event handlers
    this.connect = function () {
        // scope.domElement.oncontextmenu = function() {
        // 	return false;
        // };

        scope.domElement.addEventListener('pointerdown', onPointerDown, false)
        scope.domElement.addEventListener('pointermove', onPointerMove, false)
        scope.enabled = true
        dragging = false
    }

    //dispose event handlers
    this.dispose = function () {
        scope.domElement.removeEventListener('pointerdown', onPointerDown, false)
    }

    //called by scene
    this.update = function () {
        //raycast();

        if (dragging) {
            // if(destination.x < (dx*scale))
            // destination.x += dx * scale.x;
            // destination.y += dy * scale.y;
            // console.log(destination);

            transformCam()
        }
    }

    //called by Intro Tutorial Scene
    this.updateIntro = function () {
        if (dragging) {
            transformCam()
            if (!movedInIntro) {
                triggerIntroEvent('movedPointer')
            }
            movedInIntro = true
        }

        if (!navToFlag) {
            const frustum = new Frustum()
            const matrix = new Matrix4().multiplyMatrices(
                camera.projectionMatrix,
                camera.matrixWorldInverse
            )
            frustum.setFromProjectionMatrix(matrix)

            if (frustum.containsPoint(flag.position)) {
                // console.log('Flag in view');
                navToFlag = true
                triggerIntroEvent('navToFlag')
            }
        }
    }
}

export { DragControls }
