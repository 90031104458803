'use strict'

import * as THREE from 'three'

class MaterialLoader {
    constructor() {
        this.materials = []
        this.materialsRevealed = []
        this.pathsLoaded = []
    }

    async init() {
        let paths_normal = [
            '/img/fassade1.png',
            '/img/fassade2.png',
            '/img/fassade3.png',
            '/img/fassade4.png',
            '/img/fassade_top1.png',
            '/img/fassade_top2.png',
            '/img/fassade_top2_luecke.png',
            '/img/fassade3_ohneflagge.png',
            '/img/fassade4_luecke.png',
            '/img/fassade_baustelle.png',
            '/img/baum.png',
            '/img/demo.png',
            '/img/laterne.png',
            '/img/eingang.png',
            '/img/eingang_neu.png',
            '/img/truemmerfrau.png',
            '/img/truemmer.png',
            '/img/leistungskarte.png',
            '/img/gerda-blumen.png',
            '/img/turm.png',
            '/img/frankfurter_tor_turm.png',
            '/img/stalin.png',
            '/img/laterne_krumm.png',
            '/img/quittungsmarke.png',
            '/img/flagge_haeger.png',
            '/img/flagge_israel.png',
            '/img/flagge_pride.png',
            '/img/banner_miethai.png',
            '/img/banner_miethai_tor.png',
            '/img/banner_beute.png',
            '/img/rob.png',
            '/img/fassade_treppe.png',
            '/img/buchhandlung.png',
            '/img/briefkasten.png',
            '/img/truemmerfrau_bueckend.png',
            '/img/gerda_truemmer.png',
            '/img/baustelle.png',
            '/img/kellereingang.png',
            '/img/rob_bruestung.png',
            '/img/gerda_truemmer.png',
            '/img/herrthieme.png',
            '/img/straussberger_wohnung_bilderwand.png',
            '/img/straussberger_wohnung_mann.png',
            '/img/straussberger_wohnung_portraet.png',
            '/img/straussberger_wohnung_pflanzen.png',
            '/img/straussberger_wohnung_schreibtisch.png',
            '/img/straussberger_wohnung_glastisch.png',
            '/img/straussberger_wohnung_sitzecke.png',
            '/img/straussberger_wohnung_sofaecke.png',
            '/img/straussberger_wohnung_sessel.png',
            '/img/straussberger_wohnung_bett.png',
            '/img/straussberger_wohnung_kommode.png',
            '/img/straussberger_wohnung_statue.png',
            '/img/straussberger_wohnung_vase.png',
            '/img/straussberger_wohnung_spiegel.png',
            '/img/straussberger_wohnung_mietvertrag.png',
            '/img/straussberger_platz_turm_baum.png',
            '/img/schaben.png',
        ]

        let paths = paths_normal

        //uncomment for drawings
        //const paths = paths_normal.map(p => p.replace(".png","_edit.png"));
        //let paths = [];
        //paths.push(...paths_normal)

        paths.push('/img/Bricks064_2K_Color.jpg')
        console.log('jpg klein geschrieben')
        //paths.push('/img/Bricks064_2K_Color_edit.JPG'); //uncomment for drawing

        const manager = new THREE.LoadingManager()
        manager.onStart = function (url, itemsLoaded, itemsTotal) {
            console.log(
                'Started loading file: ' +
                    url +
                    '.\nLoaded ' +
                    itemsLoaded +
                    ' of ' +
                    itemsTotal +
                    ' files.'
            )
        }

        manager.onLoad = function () {
            console.log('Loading complete!')
        }

        manager.onProgress = function (url, itemsLoaded, itemsTotal) {
            console.log(
                'Loading file: ' +
                    url +
                    '.\nLoaded ' +
                    itemsLoaded +
                    ' of ' +
                    itemsTotal +
                    ' files.'
            )
        }

        manager.onError = function (url) {
            console.log('There was an error loading ' + url)
        }

        const loader = new THREE.TextureLoader(manager)

        let loading = await Promise.all(
            paths.map(async (path) => {
                if (this.pathsLoaded.includes(path)) {
                    console.log(path)
                    return
                }
                await loader.loadAsync(path).then((texture) => {
                    texture.needsUpdate = true
                    if (path === '/img/Bricks064_2K_Color.jpg') {
                        texture.wrapS = THREE.RepeatWrapping
                        texture.wrapT = THREE.RepeatWrapping
                        texture.repeat.set(100, 100)
                        let material = new THREE.MeshStandardMaterial({
                            map: texture,
                            transparent: true,
                        })
                        this.materialsRevealed.push(material)
                        this.materials.push(material)
                    } else if (path.includes('edit')) {
                        //BASIC MATERIAL // REVEAL
                        const material = new THREE.MeshStandardMaterial({
                            map: texture,
                            transparent: true,
                        })
                        this.materials.push(material)
                    }
                    //pictures without transparency and without being affected by light
                    else if (path.includes('wohnung')) {
                        //BASIC MATERIAL // REVEAL
                        const material = new THREE.MeshBasicMaterial({
                            map: texture,
                        })
                        this.materials.push(material) //delete this line later if using drawing
                        this.materialsRevealed.push(material)
                    } else {
                        const material = new THREE.MeshStandardMaterial({
                            map: texture,
                            transparent: true,
                        })
                        this.materialsRevealed.push(material)
                        this.materials.push(material) //delete this line later if using drawing
                    }
                    this.pathsLoaded.push(path)
                })

                // path = path.replace(".png","_edit.png")
                // console.log(path);
                // //path = path + "_edit"
                // await loader.loadAsync(path).then((texture) => {
                //     texture.needsUpdate = true;
                //     if (path === '/img/Bricks064_2K_Color.JPG'){
                //         texture.wrapS = THREE.RepeatWrapping;
                //         texture.wrapT = THREE.RepeatWrapping;
                //         texture.repeat.set( 10, 10 );
                //         const material = new THREE.MeshPhongMaterial({
                //             map: texture,
                //             transparent: true,
                //         });
                //         this.materials.push(material);
                //     }
                //     else{
                //         //PHONG MATERIAL
                //         let material = new THREE.MeshPhongMaterial({
                //             map: texture,
                //             transparent: true
                //         });
                //         this.materials.push(material);

                //     }
                // })
            })
        )

        return loading
    }

    async initIntroTutorial() {
        let paths = [
            '/img/fassade1.png',
            '/img/fassade2.png',
            '/img/fassade3.png',
            '/img/fassade4.png',
            '/img/fassade_top1.png',
            '/img/fassade_top2.png',
            '/img/fassade_top2_luecke.png',
            '/img/fassade3_ohneflagge.png',
            '/img/fassade4_luecke.png',
            '/img/baum.png',
            '/img/flagge_haeger.png',
        ]

        paths.push('/img/Bricks064_2K_Color.jpg')

        const manager = new THREE.LoadingManager()
        manager.onError = function (url) {
            console.log('There was an error loading ' + url)
        }

        const loader = new THREE.TextureLoader(manager)

        let loading = await Promise.all(
            paths.map(async (path) => {
                if (this.pathsLoaded.includes(path)) {
                    console.log(path)
                    return
                }
                await loader.loadAsync(path).then((texture) => {
                    texture.needsUpdate = true
                    if (path === '/img/Bricks064_2K_Color.jpg') {
                        texture.wrapS = THREE.RepeatWrapping
                        texture.wrapT = THREE.RepeatWrapping
                        texture.repeat.set(100, 100)
                        let material = new THREE.MeshStandardMaterial({
                            map: texture,
                            transparent: true,
                        })
                        this.materialsRevealed.push(material)
                        this.materials.push(material)
                    } else {
                        const material = new THREE.MeshStandardMaterial({
                            map: texture,
                            transparent: true,
                        })
                        this.materialsRevealed.push(material)
                        this.materials.push(material) //delete this line later if using drawing
                    }
                    this.pathsLoaded.push(path)
                })
            })
        )

        return loading
    }

    getMaterials() {
        return this.materials
    }

    getMaterialsRevealed() {
        return this.materialsRevealed
    }
}

export { MaterialLoader }
