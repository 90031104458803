<template>
    <div class="intro">
        <!-- <div class="intro_text" v-show="step == 0">
        {{text.step0.text}}
        </div> -->
        <div class="intro_video" v-if="step == 0">
            <MediaSnippet :person="'Einleitung'" :description_intro="''" :source="introVideoSource"
                :showOverlay="showOverlay" :playMedia="true" :isVideo="true" :windowSize="windowSize"
                :endAudio="endAudio" :hasCloseButton="false" @media-closed="closeMedia"> </MediaSnippet>
        </div>
        <div class="intro_choice" v-show="step == 1 || step == 3">
            <div class="intro_choice_text" v-show="step == 1">Im Tutorial erfährst du, wie du dich im interaktiven Film
                bewegen kannst. Wenn du schon einmal hier warst, kannst du das Tutorial auch überspringen und direkt in
                den Film eintauchen.</div>

            <div class="intro_choice_options">
                <div class="intro_choice_options_opt" @click="skipTo('tutorial')" @touchstart="skipTo('tutorial')">
                    <div class="intro_choice_options_opt_text" v-show="step == 1">Starte das Tutorial.</div>
                    <div class="intro_choice_options_opt_text" v-show="step == 3">Wiederhole das Tutorial.</div>
                    <ph-fast-forward class="icon" />
                </div>
                <div class="intro_choice_options_opt" @click="skipTo('start')" @touchstart="skipTo('start')">
                    <div class="intro_choice_options_opt_text">Springe direkt zum interaktiven Film.</div>
                    <ph-fast-forward class="icon" />
                </div>
            </div>
        </div>
        <div class="intro_text" v-show="step == 3">
            {{ text.step3.text }}
        </div>
        <div class="intro_ctrl_container">
            <div class="intro_ctrl_container_buttons_back">
                <div v-show="step != 0 && step != 1 && step != 3">Zurück oder Weiter</div>
                <div v-show="step == 0 || step == 3">Weiter</div>
                <div v-show="step == 1">Zurück</div>
                <div class="intro_arrows">
                    <ph-arrow-left class="icon" @click="nextStep(false)" @touchstart="nextStep(false)"
                        v-show="step != 0" />
                    <ph-arrow-right class="icon" @click="nextStep(true)" @touchstart="nextStep(true)"
                        v-show="step != 1 && step != 3" />
                </div>
            </div>
            <!-- <div class="intro_ctrl_container_buttons_skip">
                <div class="skip" v-show="step===0 | step===1">Intro überspringen</div>
                <ph-fast-forward class="icon" @click="skipTo('tutorial')" @touchstart="skipTo('tutorial')" v-show="step===0 | step===1"/>
                <div class="skip" v-show="step==2">Tutorial überspringen</div>
                <ph-fast-forward class="icon" @click="skipTo('start')" @touchstart="skipTo('start')" v-show="step==2"/>
            </div> -->
        </div>
        <div class="intro_explanation" ref="exp" v-show="step == 2">
            {{ currentTutorial }}
        </div>
        <transition @enter="enterOutro" @leave="closeOutro">
            <div v-show="outro" class="intro_outro" @click="outro = false" @touchstart="outro = false">
                {{ description_outro }}
            </div>
        </transition>
        <MediaSnippet v-bind="overlayProps" :source="mediaSource" :showOverlay="showOverlay" :playMedia="playMedia"
            :isVideo="isVideo" :windowSize="windowSize" :endAudio="endAudio" :isTutorial="true"
            @media-closed="closeMedia" @intro-task-done="markAsDone('pauseAudio')"> </MediaSnippet>
        <div class="intro_scene">
            <div id="container" ref="container" v-show="step == 2">
                <canvas id="canvas" ref="canvas"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import { PhArrowRight, PhArrowLeft, PhFastForward } from "@phosphor-icons/vue";

import MediaSnippet from './MediaSnippet.vue'

import * as THREE from 'three'
import { gsap } from 'gsap'

import { DragControls } from './js/DragControls.js'
import { MaterialLoader } from './js/loaders/MaterialLoader.js'
import { Collage } from './js/Collage.js'
import { DocElementManager } from './js/DocElementManager.js'

let scene, camera, renderer, materials, materialsRevealed, frame, controls, docElementManager

const BUILDING_LEVEL = 12
const BLOOM_SCENE = 1

export default {
    data() {
        return {
            windowSize: new THREE.Vector2(0, 0),
            step: 0,
            tutorialStep: 0,
            text: {
                step0: {
                    id: 0,
                    text: 'Erster TEil Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
                },
                step1: {
                    id: 1,
                    text: 'Intro Video',
                },
                step3: {
                    id: 3,
                    text: 'Starte jetzt die interaktive Dokumentation!',
                },
            },
            personDescription: {
                haeger: {
                    id: "haeger",
                    trigger: "flagge_haeger",
                    worldPos: new THREE.Vector3(0, 0, 0),
                    labelPos: "",
                    played: false,
                    name: "Frau Häger",
                    intro: "erinnert sich an das Flaggen während der Feierlichkeiten am ersten Mai und wie sie sich dem entziehen konnte.",
                    outro: "Frau Häger hat ihre Wohnung durch Beharrlichkeit bekommen. Bis auf den einen, oder anderen, seien die meisten Nachbarn sehr nett gewesen. Die Mehrheit waren schon Rentner, oder kurz davor. Heute gehört sie mit ihrem Mann zu der älteren Generation und beklagt, dass sie die meisten Nachbarn nicht mehr kennt."
                },
            },
            tutorial: {
                0: {
                    id: 0,
                    text: 'Klicke auf den Bildschirm und bewege den Zeiger',
                },
                1: {
                    id: 1,
                    text: 'So kannst du steuern. Navigiere zur Flagge.',
                },
                2: {
                    id: 2,
                    text: 'Klicke auf die Flagge, um eine Tonaufnahme darüber anzuhören.',
                },
                3: {
                    id: 3,
                    text: 'Unten Links stehen Informationen über die Person, die redet. Außerdem gibt es Steuerungselemente. Probiere, die Audioaufnahme zu pausieren.',
                },
                4: {
                    id: 4,
                    text: 'Schließe die Audioaufnahme, indem du auf das X drückst.',
                },
                5: {
                    id: 5,
                    text: 'Schließe die weiterführenden Informationen, indem du einmal auf den Bildschirm klickst.',
                },
                6: {
                    id: 6,
                    text: 'Nun kannst du dir weitere Element anschauen und so nach und nach alles entdecken. Jetzt weißt du, wie alles funktioniert. Klicke rechts auf weiter.',
                },
            },
            tasks: {
                //0:{id:0,title:"movedPointer",done:false},
                movedPointer: { id: 0, done: false },
                navToFlag: { id: 1, done: false },
                clickOnFlag: { id: 2, done: false },
                pauseAudio: { id: 3, done: false },
                closeAudio: { id: 4, done: false },
                closeOutro: { id: 5, done: false },
            },
            horizontalFov: 70,
            mediaSource: '',
            //introVideoSource: "/video/demo2.mp4",
            introVideoSource: 'https://storage.googleapis.com/kma-data.appspot.com/vorfilm_kma.mp4',
            playMedia: false,
            isVideo: false,
            showOverlay: false,
            overlayProps: {
                person: '',
                description_intro: '',
            },
            endAudio: false,
            outro: false,
        }
    },
    components: {
        PhArrowRight,
        PhArrowLeft,
        PhFastForward,
        MediaSnippet,
    },
    provide() {
        return {
            color: '#D4CCC8',
            size: 35,
            //height: "50%",
            mirrored: false,
        }
    },
    computed: {
        currentTutorial: function () {
            let elem = this.tutorial[this.tutorialStep]
            return elem.text
        },
        xPercentTutorial: function () {
            if (this.tutorialStep === 0) {
                return -50
            } else if (this.tutorialStep === 1) {
                return -70
            } else if (this.tutorialStep === 2) {
                return -90
            } else if (this.tutorialStep === 3) {
                return -60
            } else if (this.tutorialStep === 4) {
                return -160
            } else if (this.tutorialStep === 5) {
                return -130
            }
            return 15
        },
        yPercentTutorial: function () {
            if (this.tutorialStep === 0) {
                return -20
            } else if (this.tutorialStep === 1) {
                return 50
            } else if (this.tutorialStep === 2) {
                return 90
            } else if (this.tutorialStep === 3) {
                return -20
            } else if (this.tutorialStep === 4) {
                return 100
            } else if (this.tutorialStep === 5) {
                return -50
            }
            return 140
        },
    },
    emits: ['intro-done'],
    methods: {
        nextStep: function (forward) {
            if (forward) {
                this.step++
            } else {
                this.step--
            }
            if (this.step === 2) {
                this.startScene()
                //reset tutorial
                this.tutorialStep = 0

                for (let key in this.tasks) {
                    let task = this.tasks[key]
                    task.done = false
                }
            }
            if (this.step === 4) {
                //emit intro done
                this.$emit('intro-done')
            }
        },
        nextExplanation: function () {
            this.tutorialStep++
            let obj = this.$refs.exp

            gsap.to(obj, {
                duration: 2,
                ease: 'power3',
                xPercent: this.xPercentTutorial,
                yPercent: this.yPercentTutorial,
            })
        },
        preLoadMaterials() {
            let materialLoader = new MaterialLoader()
            return materialLoader.initIntroTutorial().then(() => {
                materials = materialLoader.getMaterials()
                materialsRevealed = materialLoader.getMaterialsRevealed()
            })
        },
        changeMedia: function (e) {
            if (e.detail.path === e.mediaSource) return //if same one already playing
            this.mediaSource = e.detail.path
            this.isVideo = e.detail.isVideo
            this.playMedia = true
            this.showOverlay = true
            window.removeEventListener('touchstart', this.preventDefault)
        },
        closeMedia: function () {
            if (!this.playMedia) return //if nothing is playing

            this.playMedia = false
            this.mediaSource = ''
            this.outro = true
            docElementManager.close()

            if (!this.isVideo) {
                this.markAsDone('closeAudio')
                this.endAudio = true
            }
        },
        triggerDocElement: function (e) {
            if (e.detail.name === 'flagge_haeger') {
                this.tasks.clickOnFlag.done = true
                this.nextExplanation()
            }
            docElementManager.triggerIntro(e.detail.name)
        },
        introTaskDone: function (e) {
            let done = e.detail.task
            //if (task==="movedPointer") this.tasks.movedPointer.done = true;

            if (done === 'movedPointer') {
                let timer
                clearTimeout(timer)
                timer = setTimeout(() => {
                    this.markAsDone(done)
                }, 1000)
            } else {
                this.markAsDone(done)
            }
        },
        markAsDone: function (done) {
            let task = this.tasks[done]

            if (!task.done) {
                task.done = true
                this.nextExplanation()
            }
        },
        setPersonIntro: function (e) {
            if (e.detail.description_intro === this.overlayProps.description_intro) return //if same one already playing

            let person = this.personDescription[e.detail.name]
            this.overlayProps.person = person.name;
            this.overlayProps.description_intro = person.intro;
            this.description_outro = person.outro;
        },
        enterOutro: function (el) {
            if (!this.outro) return

            gsap.to(el, {
                duration: 0.5,
                opacity: 1,
            })
        },
        closeOutro: function (el) {
            docElementManager.closeOverlay()
            this.showOverlay = false
            this.endAudio = false
            this.markAsDone('closeOutro')
            window.addEventListener('touchstart', this.preventDefault)

            window.removeEventListener('triggerDocElement', (e) => this.triggerDocElement(e))
            window.removeEventListener('introTaskDone', (e) => this.introTaskDone(e))
            window.removeEventListener('changeMedia', (e) => this.changeMedia(e))
            window.removeEventListener('setPersonIntro', (e) => this.setPersonIntro(e))
            window.removeEventListener('closePerson', this.closePerson)

            gsap.to(el, {
                duration: 2,
                opacity: 0,
            })
        },
        skipTo: function (destination) {
            if (destination === 'tutorial') {
                this.step = 2
                this.startScene()
            } else if (destination === 'start') {
                this.step = 4
                this.$emit('intro-done')
            }
        },
        onWindowResize: function () {
            if (this.step !== 2) return

            const width = window.innerWidth
            const height = window.innerHeight

            camera.aspect = width / height
            let horizontalFov = 70
            camera.fov = (Math.atan(Math.tan(((horizontalFov / 2) * Math.PI) / 180) / camera.aspect) * 2 * 180) / Math.PI
            camera.updateProjectionMatrix()
            renderer.setSize(width, height)
            renderer.outputEncoding = THREE.sRGBEncoding
            renderer.setClearColor(0x000000, 0)

            controls.handleResize(width, height)
        },
        startScene: function () {
            Promise.all([this.preLoadMaterials()])
                .then(() => {
                    this.initScene()
                })
                .then(() => {
                    this.animate()
                })
        },
        initScene: function () {
            //MINIMAL SCENE SETUP
            let container = this.$refs['container'] //document.getElementById("container");

            //SCENE
            scene = new THREE.Scene()

            const loader = new THREE.TextureLoader()
            loader.load('/img/alex_abend.jpg', function (t) {
                t.minFilter = THREE.LinearFilter
                scene.background = t
            })

            //CAMERA //copy paste from scene
            let ratio = window.innerWidth / window.innerHeight
            camera = new THREE.PerspectiveCamera(60, ratio, 0.1, 1000)
            camera.position.set(0, 1, 0)
            camera.lookAt(0, 1, BUILDING_LEVEL)

            let horizontalFov = this.horizontalFov
            camera.fov = (Math.atan(Math.tan(((horizontalFov / 2) * Math.PI) / 180) / camera.aspect) * 2 * 180) / Math.PI
            scene.add(camera)
            camera.updateProjectionMatrix()

            //LIGHT
            scene.add(new THREE.AmbientLight(0xffffff, 0.5))

            //GEO
            let collageHandler = new Collage(scene, materials, BUILDING_LEVEL, BLOOM_SCENE)
            collageHandler.initTutorial()

            let geo = new THREE.PlaneGeometry(50, 20)
            let material = new THREE.MeshBasicMaterial({
                color: 0x3e3f3d,
                opacity: 0.7,
                transparent: true,
            })
            let overlay = new THREE.Mesh(geo, material)
            overlay.rotateX(Math.PI)
            overlay.visible = false
            scene.add(overlay)

            //RENDERER
            const canvas = this.$refs['canvas']
            renderer = new THREE.WebGLRenderer({
                antialias: true,
                alpha: true,
                canvas: canvas,
            })
            renderer.setSize(container.clientWidth, container.clientHeight)
            renderer.outputEncoding = THREE.sRGBEncoding
            renderer.setClearColor(0x000000, 0)

            //CONTROLS
            controls = new DragControls(scene, camera, renderer.domElement)
            controls.connect()

            //DOCUMENTARY ELEMENT MANAGING
            docElementManager = new DocElementManager(camera, controls, renderer.domElement, collageHandler, overlay, materialsRevealed)

            window.addEventListener('triggerDocElement', (e) => this.triggerDocElement(e))
            window.addEventListener('introTaskDone', (e) => this.introTaskDone(e))
            window.addEventListener('changeMedia', (e) => this.changeMedia(e))
            window.addEventListener('setPersonIntro', (e) => this.setPersonIntro(e))
            window.addEventListener('closePerson', this.closePerson)
        },
        animate: function () {
            try {
                controls.updateIntro()
                frame = requestAnimationFrame(this.animate)
                renderer.render(scene, camera)
            } catch (err) {
                //catch animation error, so it is not running endlessly in error
                cancelAnimationFrame(frame)
                console.log('animation error', err)
            }
        },
    },
    //   created() {
    //     console.log("start fetch");
    //     fetch(this.introVideoSource)
    //       .then((resp) => resp.json())
    //       .then((data) => {
    //         console.log("video done", data);
    //       });
    //   },
}
</script>

<style lang="scss">
@import './assets/_config.scss';

.intro {
    min-height: 100%;
    height: 100vh;
    position: relative;
    background: $darkmiddle;
    color: $light;
    overflow: hidden;

    &_text {
        @include centered-text;
    }

    &_ctrl_container {
        display: flex;
        z-index: 5;
        position: absolute;
        right: 0;

        .icon {
            border-style: solid;
            border-width: thin;
            border-color: $lightmiddle;
            padding: 5px;
            margin: 5px;
        }
    }

    &_arrows {
        display: flex;
        flex-direction: rows;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 5;

        // &_skip{
        //     margin-top:2rem;
        //     position: relative;
        //     z-index:5;
        //     top:0;
        //     right:0;
        // }
    }

    &_choice {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center;

        &_text {
            width: 50%;
            margin-bottom: 5%;
        }

        &_options {
            display: flex;
            flex-direction: row;
            width: 75%;
            align-items: center;

            &_opt {
                flex-basis: 50%;
                border-style: solid;
                border-width: thin;
                border-color: $lightmiddle;
                padding: 5px;
                margin: 20px;

                &_text {
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }
            }
        }
    }

    &_scene {
        @include scene;
    }

    &_explanation {
        background: $darkmiddle;
        font-size: 1.5rem;
        border-radius: 10px;
        display: inline-block;
        position: absolute;
        margin: 5rem;
        z-index: 6;
    }

    &_outro {
        #close-outro {
            @include close-button;
        }

        z-index: 5;
        @include centered-text;
    }
}

@include media-s {
    .intro {
        &_text {
            font-size: 1.1rem;
            padding: 0 16vw;
        }

        &_ctrl_container {
            top: 0;
            flex-direction: row;
            justify-content: end;
            flex-direction: row;
            align-items: baseline;
            right: 0;
            margin: 0rem;

            &_buttons_back {
                padding-right: 2rem;
            }
        }

        &_choice {
            font-size: 1rem;
        }

        &_explanation {
            font-size: 1rem;
            padding: 0.5rem;
        }
    }
}

@include media-md {
    .intro {
        &_text {
            font-size: 1.5rem;
            padding: 0 15vw;
        }

        &_ctrl_container {
            right: 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            margin: 1rem;
            font-size: 1.2rem;

            &_buttons_back {
                padding: 0;
            }
        }

        &_choice {
            font-size: 1.5rem;
        }

        &_explanation {
            font-size: 1.5rem;
            padding: 1rem;
        }
    }
}
</style>