<template>
        <div id="documentary">
            <div id="landscapeAlert" v-show="!isLandscape"> 
                Bitte rotiere dein Gerät, sodass es im Querformat ist. <br> Please rotate your device to switch to landscape mode 
            </div>
            <IntroTutorial v-show="isLandscape" v-if="!introDone" @intro-done="introDone=true" ref="intro"> </IntroTutorial>
            <Scene v-show="isLandscape" v-if="introDone" ref="scene"></Scene>
        </div>
</template>

<script>
import Scene from "./Scene.vue"
import IntroTutorial from "./IntroTutorial.vue"

export default {
    name: "Documentary",
    data() {
        return {
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            isMounted:false,
            introDone:false
        }
    },
    components: {
        Scene,
        IntroTutorial
    },
    computed:{
        isLandscape(){
            if(this.windowHeight > this.windowWidth){
                return false;
            }
            else {
                return true;
            }
        }
    },
    methods:{
        onResize(){
            this.windowHeight = window.innerHeight;
            this.windowWidth = window.innerWidth;
            if(this.isMounted && this.isLandscape){
                if(this.introDone){
                    this.$refs.scene.onWindowResize();
                }
                else{
                    this.$refs.intro.onWindowResize();
                }
                
            }
        },
        preventDefault(event){
            event.preventDefault()
        }
    },
    mounted(){
        console.log("mounted documentary")
        window.addEventListener('resize', this.onResize);

        //prevent defaults on mobile, e.g select all o long touch
        window.addEventListener("touchstart", this.preventDefault);
        window.addEventListener("touchend", this.preventDefault);
        window.addEventListener("touchmove", this.preventDefault);
        window.addEventListener("touchcancel", this.preventDefault);
        this.isMounted = true;

    }
    
}
</script>

<style lang="scss" scoped>
    @import "./assets/_config.scss";
    #landscapeAlert{
        height: 100vh;
        width: 100vw;
        background: $darkmiddle;
        color:$light;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index:2;
    }
</style>
