'use strict'

import * as THREE from 'three'
import { gsap } from 'gsap'

let DocElementManager = function (
    camera,
    controls,
    domElement,
    collageHandler,
    overlay,
    materialsRevealed
) {
    this.camera = camera
    this.controls = controls
    this.domElement = domElement
    this.collageHandler = collageHandler
    this.collage = collageHandler.getCollageParent()
    this.overlay = overlay
    this.materialsRevealed = materialsRevealed

    let scope = this

    let lastCamPos = new THREE.Vector3(scope.camera.position)
    let gerdaHidden = true
    let flagHidden = true
    let wohnungHidden = true
    let briefkastenZoom = false
    let truemmerfrauStanding = false
    let mikrokosmosHidden = true

    let currentGroupName = ''

    const BLOOM_SCENE = 1

    // let GERDA_QUITTUNG;
    // let GERDA_NAME;
    // let TURM;
    // let DEMO;
    //let sound = new THREE.PositionalAudio(camera.children[0]);
    //let lastSoundObject;

    //zoom in camera to a window
    function zoomCamera(
        name,
        distance,
        { stopControls = true, onComplete, xAdd = 0, yAdd = 0 } = {}
    ) {
        //hausmiite ist bei 0,20,5
        //window ist bei 0,20,8
        //kamera muss zu 0,18,8 (2m entfernt)
        //name = fassade 2
        lastCamPos.copy(scope.camera.position)
        let object = scope.collage.getObjectByName(name)
        let aabb = new THREE.Box3()
        aabb.setFromObject(object)
        let center = aabb.getCenter(new THREE.Vector3())

        //center.y += 1; //window 3m über center
        center.z -= distance //cam 2m entfernt

        gsap.to(scope.camera.position, {
            duration: 3,
            ease: 'power3',
            x: center.x + xAdd,
            y: center.y + yAdd,
            z: center.z,
            onStart: function () {
                if (stopControls) {
                    scope.controls.movingEnabled = false
                }
            },
            onComplete: function () {
                if (typeof onComplete === 'function') {
                    onComplete()
                }
                // if (typeof onComplete === 'function'){
                //     onComplete(object);
                // }
                // if (typeof handler === 'function'){
                //     handler(object);
                // }
                //scope.controls.enabled = true;
            },
        })
    }

    function changeMaterials() {
        let group = scope.collage.getObjectByName(currentGroupName)

        //for objects in group
        for (let i = 0; i < group.children.length; i++) {
            let obj = group.children[i]

            let path = obj.userData.path

            //find revelaed material (basic material here)
            for (let i = 0; i < scope.materialsRevealed.length; i++) {
                if (scope.materialsRevealed[i].map.image.src.includes(path)) {
                    //change objects material
                    obj.material = scope.materialsRevealed[i]
                    break
                }
            }
        }

        // let path = "/img/truemmerfrau.png"
        // let obj = scope.collage.getObjectByName("truemmerfrau");

        // for (let i = 0; i < scope.materialsRevealed.length; i++) {
        //     if(scope.materialsRevealed[i].map.image.src.includes(path)){
        //         obj.material = scope.materialsRevealed[i];
        //         break;
        //     }
        // }
        currentGroupName = ''
    }

    function startMedia(path, isVideo, { hasMedia = true } = {}) {
        const event = new CustomEvent('changeMedia', {
            bubbles: true,
            detail: {
                path: path,
                isVideo: isVideo,
                hasMedia: hasMedia,
            },
        })
        scope.domElement.dispatchEvent(event)
    }

    function triggerSetPerson(name) {
        const event = new CustomEvent('setPerson', {
            bubbles: true,
            detail: {
                name: name,
            },
        })
        console.log('emitting event to set person', name)
        scope.domElement.dispatchEvent(event)
    }

    function setOverlay(name) {
        if (scope.overlay.visible && scope.overlay.material.opacity !== 0.0) return //if already displaying

        let object = scope.collage.getObjectByName(name)

        scope.overlay.visible = true
        scope.overlay.material.opacity = 0.0
        scope.overlay.position.copy(object.position)
        scope.overlay.position.z += 0.01

        scope.overlay.renderOrder = object.renderOrder - 0.05

        gsap.to(scope.overlay.material, {
            delay: 3, //for zooming in camera
            duration: 0.5,
            opacity: 0.7,
        })
    }

    function zoomOutCamera() {
        gsap.to(scope.camera.position, {
            duration: 3,
            ease: 'power3',
            x: lastCamPos.x,
            y: lastCamPos.y,
            z: 0,
            onComplete: function () {
                scope.controls.movingEnabled = true
            },
        })
    }

    function removeBloom(name) {
        let object = scope.collage.getObjectByName(name)
        object.layers.disable(BLOOM_SCENE)

        if (name === 'truemmerfrau_bueckend') {
            removeBloom('truemmer')
        }
    }
    // function closePerson(){
    //     const event = new CustomEvent("closePerson",{
    //         bubbles: true,
    //     });
    //     scope.domElement.dispatchEvent(event);
    // }

    // function addSound(path,name){
    //     //add audio element to info element

    //     const parent = sound.parent;
    //     let object = scope.collage.getObjectByName(name);

    //     if (sound.isPlaying && parent instanceof THREE.Object3D)
    //     {
    //         console.log("sound already playing")
    //         removeSound();
    //         return;
    //     }

    //     let audioFile = undefined;
    //     for (let i = 0; i < audios.length; i++) {
    //         if(audios[i].path.includes(path)){
    //             audioFile = audios[i].audio;
    //         }
    //     }
    //     if(audioFile !== undefined){
    //         sound.setBuffer(audioFile);
    //         sound.setRefDistance(2);
    //         sound.play();

    //         object.add(sound);

    //     }
    //     else{
    //         console.log("audio not found: ",path)
    //     }
    // }

    // function removeSound(){
    //     sound.stop();
    //     const parent = sound.parent;
    //     parent.remove(sound);
    // }

    this.closeOverlay = function () {
        gsap.to(scope.overlay.material, {
            //delay:5, //damit man description outro (delay 6) lesen kann
            duration: 0.5,
            opacity: 0,
            onComplete: function () {
                scope.overlay.visible = false
                //changeMaterials();
            },
        })
    }

    this.close = function () {
        if (!gerdaHidden) {
            console.log('gerda is not hidden on close, so reversing zimeline now')
            collageHandler.showGerda(false)
            gerdaHidden = true
        }
        if (!wohnungHidden) {
            console.log('zimmer is not hidden, so hide now')
            collageHandler.showWohnung(false)
            wohnungHidden = true
        }
        if (!mikrokosmosHidden) {
            console.log('zimmer is not hidden, so hide now')
            collageHandler.showMikrokosmos(false)
            mikrokosmosHidden = true
        }
        zoomOutCamera()
    }

    this.trigger = function (name) {
        if (name === 'demo') {
            zoomCamera(name, 1.5, {
                onComplete: function () {
                    startMedia(
                        'https://storage.googleapis.com/kma-data.appspot.com/demo1.mp4',
                        true
                    )
                },
            })
            currentGroupName = 'DEMO'
            removeBloom(name)
            changeMaterials()
            triggerSetPerson('demo')
        } else if (name === 'banner_beute') {
            zoomCamera(name, 2.0, {
                onComplete: function () {
                    startMedia(
                        'https://storage.googleapis.com/kma-data.appspot.com/demo2.mp4',
                        true
                    )
                },
            })
            currentGroupName = 'DEMO'
            removeBloom(name)
            changeMaterials()
            triggerSetPerson('mieterbeirat')
        } else if (name === 'truemmerfrau_bueckend') {
            truemmerfrauStanding = !truemmerfrauStanding
            collageHandler.standTruemmerfrau(truemmerfrauStanding)
            removeBloom(name)
        } else if (name === 'gerda_truemmer') {
            if (!gerdaHidden) return

            collageHandler.showGerda(true)
            setOverlay('truemmerfrau')
            triggerSetPerson('goldschmidt_truemmer')
            startMedia('/audio/aufbaulose.wav', false)
            gerdaHidden = false
            zoomCamera(name, 8.0, { yAdd: 4, stopControls: false })
            currentGroupName = 'GERDA_TRUEMMER'
            removeBloom(name)
            changeMaterials()
        } else if (name === 'turm_flagge') {
            collageHandler.showFlag(flagHidden)
            flagHidden = !flagHidden

            if (!flagHidden) {
                removeBloom(name) //turm bloom weg
                name = 'flagge_haeger'
                //addSound("/audio/fahnen.mp3",name);
                startMedia('/audio/fahnen.mp3', false)
                zoomCamera(name, 5.0, { stopControls: false })
                triggerSetPerson('haeger')
                setOverlay('flagge_haeger')
                removeBloom(name)

                currentGroupName = 'TURM'
                //changeMaterials();
            } else {
                //this.zoomOutCamera();
                //removeSound();
                //closePerson();
            }
        } else if (name === 'briefkasten') {
            if (!briefkastenZoom) {
                zoomCamera(name, 0.7, { stopControls: false })
                startMedia('/audio/name.wav', false)
                triggerSetPerson('goldschmidt_name')
                setOverlay(name)
                currentGroupName = 'GERDA_NAME'
                removeBloom(name)
                //changeMaterials();
            }
            briefkastenZoom = !briefkastenZoom
        } else if (name === 'fassade_baustelle') {
            zoomCamera('stalin', 8.0, { stopControls: false, yAdd: 0.5 })
            triggerSetPerson('stalin')
            startMedia('', false, { hasMedia: false })
        } else if (name === 'herrthiemetrigger') {
            name = 'herrthieme'
            zoomCamera(name, 2.0, {
                onComplete: function () {
                    startMedia(
                        'https://storage.googleapis.com/kma-data.appspot.com/herrthieme.mp4',
                        true
                    )
                    triggerSetPerson('thieme')
                    removeBloom(name)
                    //changeMaterials();
                },
            })
        } else if (name === 'straussbergertrigger') {
            if (wohnungHidden) {
                wohnungHidden = false
                name = 'sw_portraet'
                removeBloom(name)
                triggerSetPerson('winnington')
                startMedia('/audio/wohnung_straussberger.mp3', false)
                zoomCamera(name, 3.5, {
                    yAdd: 1.8,
                    onComplete: function () {
                        collageHandler.showWohnung(true)
                        setOverlay('turm_straussberger')
                        //changeMaterials();
                    },
                })
            }
        } else if (name === 'schabentrigger') {
            name = 'schaben'

            currentGroupName = 'SCHABEN'

            name = 'schaben'
            zoomCamera(name, 1.0)
            startMedia('/audio/schaben.wav', false)
            triggerSetPerson('schaben')
            setOverlay(name)
            removeBloom('turm_straussberger')
            removeBloom(name)
            changeMaterials()
        } else if (name === 'flagge_israel') {
            if (!mikrokosmosHidden) return
            zoomCamera('flagge_israel', 8.0, { stopControls: false, yAdd: 0.5 })
            triggerSetPerson('mikrokosmos')
            startMedia('', false, { hasMedia: false })
            removeBloom(name)
            mikrokosmosHidden = false
            collageHandler.showMikrokosmos(true)
        }
    }

    this.triggerIntro = function (name) {
        if (name === 'flagge_haeger') {
            startMedia('/audio/fahnen.mp3', false)
            zoomCamera(name, 5.0, { stopControls: false })
            triggerSetPerson('haeger')
            setOverlay('flagge_haeger')
        }
    }
}

export { DocElementManager }
