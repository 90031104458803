'use strict'

import * as THREE from 'three'
import { gsap } from 'gsap'
import { randFloat } from 'three/src/math/MathUtils'

let Collage = function (scene, materials, BUILDING_LEVEL, BLOOM_SCENE) {
    this.scene = scene
    this.materials = materials

    this.collageParent = new THREE.Object3D()
    this.collageParent.name = 'collage'

    let scope = this
    //let paths = ['/img/fassade2.jpg'];
    let position = new THREE.Vector3()
    let size = new THREE.Vector3(10, 10, 0.1)
    let lastHeight = 0.0
    let tlGerda = gsap.timeline({ onReverseComplete: finishReverse })
    let tlFlag = gsap.timeline({ onReverseComplete: finishReverse })
    let tlWohnung = gsap.timeline({ onReverseComplete: finishReverse })
    let tlMikrokosmos = gsap.timeline({ onReverseComplete: finishReverse })

    //gerda
    let leistungskarte, gerdaBlumen, truemmerfrau, quittungsmarke
    let sw_portraet, sw_pflanzen, sw_mann, sw_glastisch, sw_sitzecke, sw_bilderwand, sw_schreibtisch
    let sw_sofaecke, sw_sessel, sw_bett, sw_kommode, sw_statue, sw_vase, sw_spiegel, sw_mietvertrag
    let flagge_pride, flagge_israel
    let gerdaHidden = true
    let wohnungHidden = true
    let mikrokosmosHidden = true

    //flagge
    let flagge_haeger

    //GROUPS of collage parts
    const GERDA_TRUEMMER = new THREE.Group()
    const GERDA_NAME = new THREE.Group()
    const TURM = new THREE.Group()
    const DEMO = new THREE.Group()
    const STRAUSSBERGER = new THREE.Group()
    const UNKNOWN = new THREE.Group()
    const SCHABEN = new THREE.Group()
    const MIKROKOSMOS = new THREE.Group()

    //naming
    GERDA_TRUEMMER.name = 'GERDA_TRUEMMER'
    GERDA_NAME.name = 'GERDA_NAME'
    TURM.name = 'TURM'
    DEMO.name = 'DEMO'
    STRAUSSBERGER.name = 'STRAUSSBERGER'
    UNKNOWN.name = 'UNKNOWN'
    SCHABEN.name = 'SCHABEN'
    MIKROKOSMOS.name = 'MIKROKOSMOS'

    //RENDERORDER: 0 first, 1 last (just so I keep track)
    //0.0 : boden
    //0.1 : hintere fassade, hinter fenster, flagge
    //0.2 : fassade, stalin
    //0.3 : fassadenelemente, eingang, baustelle
    //0.4 : briefkasten
    //0.5 : all other elements
    //0.6 : briefkasten in eingang, gerda standing and truemmerfrau,quittungsmarken
    //0.7 : turm vorne, rkumme laterne, truemmer

    function addVideoTexture(path, position, scale, name = '') {
        const video = document.getElementById('videoTexture')
        video.src = path
        video.loop = true
        video.muted = true
        video.load()
        video.play()

        const texture = new THREE.VideoTexture(video)
        texture.format = THREE.RGBAFormat
        texture.encoding = THREE.sRGBEncoding

        if (texture !== undefined) {
            size.set(1.0, 0.6, 0.01)
            //size.set(1.0, texture.map.image.height / texture.map.image.width, 0.01);

            const box = new THREE.BoxGeometry(size.x, size.y, size.z)
            box.scale(scale, scale, 1.0)
            const material = new THREE.MeshPhongMaterial({ color: '#ffffff', map: texture })
            let img = new THREE.Mesh(box, material)

            img.position.copy(position)
            img.name = name
            let bbox = new THREE.Box3().setFromObject(img)
            //store height for placing objects on top of each other
            lastHeight = bbox.max.y - bbox.min.y
            img.position.y += lastHeight * 0.5
            scope.collageParent.add(img)
            return img
        } else {
            console.log('texture not found: ', path)
        }
    }

    function addImage(
        path,
        position,
        scale,
        name = '',
        { rotate = false, renderOrder = 0.5, group = UNKNOWN, isTrigger = false } = {}
    ) {
        //path = path.replace(".png","_edit.png") //uncomment for drawing
        let texture = undefined
        for (let i = 0; i < materials.length; i++) {
            if (materials[i].map.image.src.includes(path)) {
                texture = scope.materials[i]
            }
        }

        if (texture !== undefined) {
            size.set(1.0, texture.map.image.height / texture.map.image.width, 0.01)

            //const box = new THREE.BoxGeometry(size.x,size.y,size.z);
            const box = new THREE.PlaneGeometry(size.x, size.y)
            box.scale(scale, scale, 1.0)
            let img = new THREE.Mesh(box, texture)
            img.rotateX(Math.PI)
            img.rotateZ(Math.PI)

            if (rotate) {
                //kellereingang: rotateX(290)
                img.rotateX(290)
            }

            img.position.copy(position)
            img.name = name
            //path = path.replace("_edit.png",".png") //uncomment for drawing
            img.userData = { path: path } //store image path

            let bbox = new THREE.Box3().setFromObject(img)
            //store height for placing objects on top of each other
            lastHeight = bbox.max.y - bbox.min.y

            img.position.y += lastHeight * 0.5
            img.renderOrder = renderOrder
            if (isTrigger) {
                img.layers.toggle(BLOOM_SCENE)
            }
            group.add(img)
            //scope.collageParent.add(img);
            return img
        } else {
            console.log('texture not found: ', path)
        }
    }

    async function addTexturePlane(path, position, scale, name = '', { plane = false } = {}) {
        let texture = undefined
        for (let i = 0; i < materials.length; i++) {
            if (materials[i].map.image.src.includes(path)) {
                texture = scope.materials[i]
            }
        }

        if (texture !== undefined) {
            size.set(1.0, texture.map.image.height / texture.map.image.width, 0.01)

            if (plane) {
                const planeGeo = new THREE.PlaneGeometry(size.x * scale, size.y * scale)

                texture.side = THREE.DoubleSide
                const img = new THREE.Mesh(planeGeo, texture)
                img.position.copy(position)
                img.rotateX(-Math.PI / 2)
                let bbox = new THREE.Box3().setFromObject(img)
                img.position.z -= (bbox.max.z - bbox.min.z) * 0.3 //only 0.3 to have something in the back as well
                img.name = name
                img.renderOrder = 0 //to avoid the white spaces when overlapping alpha materials
                scope.collageParent.add(img)
                return img
            }
        }
    }

    function finishReverse() {
        if (!gerdaHidden || !wohnungHidden) {
            let mainObject
            let object1
            let object2
            let object3
            if (!wohnungHidden) {
                // mainObject = sw_portraet;
                // object1 = sw_pflanzen;
                // object2 = sw_mann;
                // object3 = sw_mann;
                wohnungHidden = true
                tlWohnung.clear()
                tlWohnung.eventCallback('onComplete', null)
            } else if (!gerdaHidden) {
                mainObject = truemmerfrau
                object1 = leistungskarte
                object2 = gerdaBlumen
                object3 = quittungsmarke
                gerdaHidden = true
                let pos = new THREE.Vector3()
                gsap.to(object1.position, {
                    onStart: function () {
                        pos.copy(mainObject.position)
                    },
                    duration: 0.5,
                    ease: 'power3',
                    x: pos.x,
                    y: pos.y,
                    z: pos.z,
                })
                gsap.to(object2.position, {
                    duration: 0.5,
                    ease: 'power3',
                    x: pos.x,
                    y: pos.y,
                    z: pos.z,
                })
                gsap.to(object3.position, {
                    duration: 0.5,
                    ease: 'power3',
                    x: pos.x,
                    y: pos.y,
                    z: pos.z,
                })
                object1.visible = false
                object2.visible = false
                object3.visible = false
                tlGerda.clear()
                tlGerda.eventCallback('onComplete', null)
            }
        } else {
            tlFlag.clear()
        }
    }

    function addInvisibleTriggerPlane(position, size, name) {
        let geo = new THREE.PlaneGeometry(size.x, size.y)
        let material = new THREE.MeshBasicMaterial({ color: '#fff', alphaTest: 0, visible: false })
        let plane = new THREE.Mesh(geo, material)
        plane.position.copy(position)
        plane.rotateX(Math.PI)
        plane.rotateZ(Math.PI)
        plane.name = name
        scope.collageParent.add(plane)
    }

    function initWohnung() {
        position.z = BUILDING_LEVEL - 5.3
        position.y = 2.0
        position.x = 22
        addImage('img/straussberger_wohnung_portraet.png', position, 1.8, 'sw_portraet', {
            isTrigger: true,
            renderOrder: 0.1,
            group: STRAUSSBERGER,
        })

        position.z = BUILDING_LEVEL - 5.6
        position.y += 0.5
        addInvisibleTriggerPlane(position, new THREE.Vector2(2.5, 0.8), 'straussbergertrigger')

        position.z = BUILDING_LEVEL - 5.2
        position.y = 2.3 + 2.5 //since I move image up a bit
        let scale = 1.0
        addImage('img/straussberger_wohnung_mann.png', position, scale, 'sw_mann', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_pflanzen.png', position, scale, 'sw_pflanzen', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_glastisch.png', position, scale, 'sw_glastisch', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_sitzecke.png', position, scale, 'sw_sitzecke', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_bilderwand.png', position, scale, 'sw_bilderwand', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_schreibtisch.png', position, scale, 'sw_schreibtisch', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_sofaecke.png', position, scale, 'sw_sofaecke', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_sessel.png', position, scale, 'sw_sessel', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_bett.png', position, scale, 'sw_bett', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_kommode.png', position, scale, 'sw_kommode', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_statue.png', position, scale, 'sw_statue', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_vase.png', position, scale, 'sw_vase', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_spiegel.png', position, scale, 'sw_spiegel', {
            group: STRAUSSBERGER,
        })
        addImage('img/straussberger_wohnung_mietvertrag.png', position, scale, 'sw_mietvertrag', {
            group: STRAUSSBERGER,
        })
    }

    function initFassade() {
        //BUILDING
        position.z = BUILDING_LEVEL
        for (let i = -5; i <= 5; i++) {
            position.y = 0
            position.x = i * 10
            //addImage('/img/fassade2.jpg',position,size,"fassade2");

            let name = 'fassade' + i + '_a'

            if (i === -3 || i === 3) {
                addImage('/img/fassade2.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_a', '_b')
                addImage('/img/fassade4.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_b', '_c')
                addImage('/img/fassade_top2.png', position, 10.0, name, { renderOrder: 0.2 })
            } else if (i === 2) {
                addImage('/img/fassade_baustelle.png', position, 10.0, 'fassade_baustelle', {
                    renderOrder: 0.2,
                    group: GERDA_NAME,
                })
                position.y += lastHeight
                name = name.replace('_a', '_b')
                addImage('/img/fassade3_ohneflagge.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_b', '_c')
                addImage('/img/fassade_top1.png', position, 10.0, name, {
                    renderOrder: 0.2,
                    group: GERDA_NAME,
                })
            } else if (i == 1) {
                addImage('/img/fassade1.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_a', '_b')
                addImage('/img/fassade4_luecke.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_b', '_c')
                addImage('/img/fassade_top2.png', position, 10.0, name, { renderOrder: 0.2 })
            } else if (i === 0) {
                addImage('/img/fassade1.png', position, 10.0, name, {
                    renderOrder: 0.2,
                    group: GERDA_NAME,
                })
                position.y += lastHeight
                name = name.replace('_a', '_b')
                addImage('/img/fassade2.png', position, 10.0, name, {
                    renderOrder: 0.2,
                    group: GERDA_NAME,
                })
                position.y += lastHeight
                name = name.replace('_b', '_c')
                addImage('/img/fassade_top1.png', position, 10.0, name, {
                    renderOrder: 0.2,
                    group: GERDA_NAME,
                })
            } else if (i === -1) {
                addImage('/img/fassade4_luecke.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_a', '_b')
                addImage('/img/fassade3.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_b', '_c')
                addImage('/img/fassade_top2_luecke.png', position, 10.0, name, {
                    renderOrder: 0.2,
                    group: TURM,
                })
            } else if (i === -2) {
                position.z -= 0.1
                addImage('/img/buchhandlung.png', position, 10.2, 'buchhandlung', {
                    renderOrder: 0.3,
                    group: TURM,
                })
                position.z += 0.1
                position.y += lastHeight - 0.8
                name = name.replace('_a', '_b')
                addImage('/img/fassade1.png', position, 10.0, name, {
                    renderOrder: 0.2,
                    group: TURM,
                })
                position.y += lastHeight
                name = name.replace('_b', '_c')
                addImage('/img/fassade_top1.png', position, 10.0, name, { renderOrder: 0.2 })
            } else {
                addImage('/img/fassade1.png', position, 10.0, name, {
                    renderOrder: 0.2,
                    group: GERDA_NAME,
                })
                position.y += lastHeight
                name = name.replace('_a', '_b')
                addImage('/img/fassade2.png', position, 10.0, name, {
                    renderOrder: 0.2,
                    group: GERDA_NAME,
                })
                position.y += lastHeight
                name = name.replace('_b', '_c')
                addImage('/img/fassade_top1.png', position, 10.0, name, {
                    renderOrder: 0.2,
                    group: GERDA_NAME,
                })
            }
            scope.scene.add(scope.collageParent)
        }

        //BACKGROUND_BUILDINGS
        position.z = BUILDING_LEVEL + 8
        let name = 'fassade_hinten'
        let path = ''
        for (let i = -6; i <= 6; i++) {
            position.y = 0
            position.x = i * 10
            for (let i = 0; i <= 3; i++) {
                path = '/img/fassade' + getRandomInt(1, 4) + '.png'
                addImage(path, position, 10.0, name, { renderOrder: 0.1 })
                position.y += lastHeight
            }
            path = '/img/fassade_top1.png'
            addImage(path, position, 10.0, name, { renderOrder: 0.1 })
            position.y += lastHeight
        }
    }

    function getRandomInt(min, max) {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min + 1) + min) // inclusive
    }

    this.init = function () {
        initFassade()

        position.z = BUILDING_LEVEL + 0.1
        position.y = 5.4
        position.x = -10.4
        addVideoTexture('/video/fahne_fassade.mp4', position, 8.7, 'gif_fahnen', {
            renderOrder: 0.1,
        })

        //THIEME
        position.z = BUILDING_LEVEL + 0.2
        position.y = 5.2
        position.x = 10
        addImage('img/herrthieme.png', position, 2.5, 'herrthieme', {
            isTrigger: true,
            renderOrder: 0.1,
        })

        position.z = BUILDING_LEVEL - 0.2
        position.y += 0.75
        let size = new THREE.Vector2(2.5, 1.5)
        addInvisibleTriggerPlane(position, size, 'herrthiemetrigger')

        //EINGANG
        position.z = BUILDING_LEVEL - 0.5
        position.y = 0
        position.x = 25
        addImage('/img/eingang.png', position, 8.0, 'eingang')

        position.z = BUILDING_LEVEL - 0.5
        position.y = 0
        position.x = 2
        addImage('/img/eingang_neu.png', position, 7.0, 'eingang_neu', { renderOrder: 0.3 })

        position.z = BUILDING_LEVEL - 0.52
        position.y = 0.6
        position.x = 4.4
        addImage('/img/briefkasten.png', position, 0.4, 'briefkasten', {
            group: GERDA_NAME,
            isTrigger: true,
            renderOrder: 0.4,
        })

        //FASSADENELEMENTE
        position.z = BUILDING_LEVEL - 0.2
        position.x = -2
        position.y = 2
        addImage('/img/fassade_treppe.png', position, 3.2, 'fassade_treppe', {
            renderOrder: 0.25,
            group: GERDA_NAME,
        })

        position.z = BUILDING_LEVEL - 0.01
        position.x = -9.4
        position.y = 4.9
        addImage('/img/flagge_israel.png', position, 0.6, 'flagge_israel', {
            group: MIKROKOSMOS,
            isTrigger: true,
            renderOrder: 0.25,
        })

        position.x = -9
        position.y = 8
        addImage('/img/flagge_pride.png', position, 0.6, 'flagge_pride', {
            group: MIKROKOSMOS,
        })

        //TURM UND FLAGGE
        position.z = BUILDING_LEVEL + 0.2
        position.y = 9
        position.x = 2
        addImage('/img/turm.png', position, 5.0, 'turm_flagge', {
            group: TURM,
            isTrigger: true,
        })

        position.z = BUILDING_LEVEL + 0.4
        position.y = 15
        addImage('/img/flagge_haeger.png', position, 1.0, 'flagge_haeger', {
            renderOrder: 0.1,
            group: TURM,
        })

        //TURM VORNE
        position.z = BUILDING_LEVEL - 4.0
        position.y = 0
        position.x = -22
        addImage('/img/frankfurter_tor_turm.png', position, 4.0, 'franfurter_tor', {
            renderOrder: 0.7,
        })

        position.z = BUILDING_LEVEL - 4.1
        position.y = 6.2
        position.x -= 0.5
        addImage('/img/banner_beute.png', position, 1.0, 'banner_beute', {
            group: DEMO,
            isTrigger: true,
            renderOrder: 0.8,
        })

        //TURM SCHABE
        position.z = BUILDING_LEVEL - 5.4
        position.x = 22.2
        position.y = 0
        //addImage('/img/turm.png',position,2.0,"turm2",{renderOrder:0.7})

        addImage('/img/straussberger_platz_turm_baum.png', position, 6.0, 'turm_straussberger', {
            renderOrder: 0.7,
        })
        position.y = 7.5
        position.z -= 0.1
        position.x = 21
        addImage('/img/schaben.png', position, 0.5, 'schaben', {
            renderOrder: 0.8,
            group: SCHABEN,
            isTrigger: false,
        })

        position.z = BUILDING_LEVEL - 5.6
        addInvisibleTriggerPlane(position, new THREE.Vector2(0.8, 0.8), 'schabentrigger')

        //TREE
        position.y = 0
        position.z = BUILDING_LEVEL - 2
        position.x = -4
        addImage('/img/baum.png', position, 4.0, 'baum')

        position.z = BUILDING_LEVEL - 5.6
        position.x = 23.5
        addImage('/img/baum.png', position, 2.5, 'baum', { renderOrder: 0.8 })

        //STALIN
        position.z = BUILDING_LEVEL + 5.0
        position.y = 0
        position.x = 18
        addImage('/img/stalin.png', position, 1.0, 'stalin', { renderOrder: 0.2 })

        // position.z = BUILDING_LEVEL - 1.3;
        // position.x = 27;
        // position.y = 0;
        // addImage('/img/baustelle.png',position,3.0,"baustelle",{renderOrder:0.3})

        //STRAUSSBERGER ZIMMER
        initWohnung()

        //LATERNEN
        position.y = 0
        //position.z = BUILDING_LEVEL - 1.7;
        for (let i = -5; i <= 5; i++) {
            position.x = i * 5.5 + 1
            position.z = randFloat(BUILDING_LEVEL - 1.9, BUILDING_LEVEL - 1.5)
            addImage('/img/laterne.png', position, 1.0, 'laterne')
        }
        position.x = 9
        position.z = BUILDING_LEVEL - 4
        position.y = 0
        addImage('/img/laterne_krumm.png', position, 1.0, 'laterne_krumm', { renderOrder: 0.7 })
        position.z = BUILDING_LEVEL - 6
        position.x = -10
        addImage('/img/laterne_krumm.png', position, 1.0, 'laterne_krumm', { renderOrder: 0.7 })

        //DEMO
        position.x = -3
        position.y = 0
        position.z = BUILDING_LEVEL - 2.5
        addImage('/img/demo.png', position, 3.0, 'demo', { group: DEMO, isTrigger: true })

        //TRUEMMER
        position.x = 12
        position.z = BUILDING_LEVEL - 2.5
        addImage('/img/truemmerfrau_bueckend.png', position, 2.0, 'truemmerfrau_bueckend', {
            group: GERDA_TRUEMMER,
            renderOrder: 0.6,
            isTrigger: true,
        })

        position.x += 0.3
        position.z = BUILDING_LEVEL - 2.7
        addImage('/img/truemmer.png', position, 3.0, 'truemmer', {
            group: GERDA_TRUEMMER,
            renderOrder: 0.7,
            isTrigger: true,
        })

        position.x = 26
        position.z = BUILDING_LEVEL - 2.5
        addImage('/img/truemmer.png', position, 2.0, 'truemmer')

        position.z = BUILDING_LEVEL - 4.9
        addImage('/img/leistungskarte.png', position, 0.5, 'leistungskarte', {
            group: GERDA_TRUEMMER,
            renderOrder: 0.72,
        })
        addImage('/img/gerda-blumen.png', position, 0.5, 'gerda-blumen', {
            group: GERDA_TRUEMMER,
            renderOrder: 0.72,
        })
        addImage('/img/quittungsmarke.png', position, 0.5, 'quittungsmarke', {
            group: GERDA_TRUEMMER,
            renderOrder: 0.72,
        })

        //BANNER

        // position.x = 10;
        // position.y = 8;
        // addImage('/img/rob.png',position,6.0,"rob")

        //position.x = 19.9;
        position.x = -20
        position.z = BUILDING_LEVEL - 0.2
        position.y = 7 //11
        addImage('/img/rob_bruestung.png', position, 9.8, 'rob_bruestung', { group: DEMO })

        position.x = -30
        position.z = BUILDING_LEVEL - 0.1
        position.y = 0
        addImage('/img/banner_miethai_tor.png', position, 3.0, 'miethai_tor', { group: DEMO })

        position.x = -9
        position.z = BUILDING_LEVEL - 0.1
        position.y = 4.1
        //addImage('/img/banner_miethai.png',position,2.0,"miethai",{group:DEMO})

        //KELLER
        // position.z = BUILDING_LEVEL - 4;
        // position.x = 20;
        // addImage('/img/kellereingang.png',position,3.0,"kellereingang",{rotate:true})

        //STRASSE
        position.z = BUILDING_LEVEL - 3
        position.y = 0
        position.x = 0
        //addImage('/img/strasse.png',position,30.0,"strasse",{plane:true})
        addTexturePlane('/img/Bricks064_2K_Color.jpg', position, 100.0, 'strasse', { plane: true })

        //ADD GROUPS TO COLLAGE
        scope.collageParent.add(UNKNOWN)
        scope.collageParent.add(GERDA_TRUEMMER)
        scope.collageParent.add(GERDA_NAME)
        scope.collageParent.add(TURM)
        scope.collageParent.add(DEMO)
        scope.collageParent.add(STRAUSSBERGER)
        scope.collageParent.add(SCHABEN)
        scope.collageParent.add(MIKROKOSMOS)
        //ADD COLLAGE TO SCENE
        scope.scene.add(scope.collageParent)

        //HIDE BONUS ELEMENTS

        //TODO: DUPLICATED CODE
        leistungskarte = GERDA_TRUEMMER.getObjectByName('leistungskarte')
        gerdaBlumen = GERDA_TRUEMMER.getObjectByName('gerda-blumen')
        quittungsmarke = GERDA_TRUEMMER.getObjectByName('quittungsmarke')
        sw_pflanzen = STRAUSSBERGER.getObjectByName('sw_pflanzen')
        sw_mann = STRAUSSBERGER.getObjectByName('sw_mann')
        sw_glastisch = STRAUSSBERGER.getObjectByName('sw_glastisch')
        sw_sitzecke = STRAUSSBERGER.getObjectByName('sw_sitzecke')
        sw_bilderwand = STRAUSSBERGER.getObjectByName('sw_bilderwand')
        sw_schreibtisch = STRAUSSBERGER.getObjectByName('sw_schreibtisch')
        sw_sofaecke = STRAUSSBERGER.getObjectByName('sw_sofaecke')
        sw_sessel = STRAUSSBERGER.getObjectByName('sw_sessel')
        sw_bett = STRAUSSBERGER.getObjectByName('sw_bett')
        sw_kommode = STRAUSSBERGER.getObjectByName('sw_kommode')
        sw_statue = STRAUSSBERGER.getObjectByName('sw_statue')
        sw_vase = STRAUSSBERGER.getObjectByName('sw_vase')
        sw_spiegel = STRAUSSBERGER.getObjectByName('sw_spiegel')
        sw_mietvertrag = STRAUSSBERGER.getObjectByName('sw_mietvertrag')
        flagge_pride = MIKROKOSMOS.getObjectByName('flagge_pride')

        leistungskarte.visible = false
        gerdaBlumen.visible = false
        quittungsmarke.visible = false
        sw_pflanzen.visible = false
        sw_mann.visible = false
        sw_glastisch.visible = false
        sw_sitzecke.visible = false
        sw_bilderwand.visible = false
        sw_schreibtisch.visible = false
        sw_sofaecke.visible = false
        sw_sessel.visible = false
        sw_bett.visible = false
        sw_kommode.visible = false
        sw_statue.visible = false
        sw_vase.visible = false
        sw_spiegel.visible = false
        sw_mietvertrag.visible = false
        flagge_pride.visible = false
    }

    this.initTutorial = function () {
        //BUILDING
        position.z = BUILDING_LEVEL
        for (let i = -1; i <= 1; i++) {
            position.y = 0
            position.x = i * 10
            //addImage('/img/fassade2.jpg',position,size,"fassade2");

            let name = 'fassade' + i + '_a'

            if (i === -1) {
                addImage('/img/fassade3.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_a', '_b')
                addImage('/img/fassade4_luecke.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_b', '_c')
                addImage('/img/fassade_top1.png', position, 10.0, name, { renderOrder: 0.2 })
            } else if (i == 1) {
                addImage('/img/fassade1.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_a', '_b')
                addImage('/img/fassade3_ohneflagge.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_b', '_c')
                addImage('/img/fassade_top2.png', position, 10.0, name, { renderOrder: 0.2 })
            } else {
                addImage('/img/fassade1.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_a', '_b')
                addImage('/img/fassade2.png', position, 10.0, name, { renderOrder: 0.2 })
                position.y += lastHeight
                name = name.replace('_b', '_c')
                addImage('/img/fassade_top1.png', position, 10.0, name, { renderOrder: 0.2 })
            }
            scope.scene.add(scope.collageParent)
        }

        //TREE
        position.y = 0
        position.z = BUILDING_LEVEL - 5
        position.x = 0
        addImage('/img/baum.png', position, 4.0, 'baum')

        //FLAGGE
        position.x = -8
        position.z = BUILDING_LEVEL - 1
        position.y = 6
        addImage('/img/flagge_haeger.png', position, 2.0, 'flagge_haeger')

        //STRASSE
        position.z = BUILDING_LEVEL - 3
        position.y = 0
        position.x = 0
        //addImage('/img/strasse.png',position,30.0,"strasse",{plane:true})
        addTexturePlane('/img/Bricks064_2K_Color.jpg', position, 100.0, 'strasse', { plane: true })

        scope.collageParent.add(UNKNOWN)
        scope.scene.add(scope.collageParent)
    }

    this.getCollageParent = function () {
        return scope.collageParent
    }

    this.showGerda = function (show) {
        //gerda can move towards camera
        //and higher up
        //so that the elements can spread out between her

        if (show) {
            truemmerfrau = scope.collageParent.getObjectByName('gerda_truemmer')
            let pos = new THREE.Vector3()
            pos.copy(truemmerfrau.position)

            let scale = new THREE.Vector3()

            tlGerda = gsap.timeline({ onReverseComplete: finishReverse })

            //TODO: BUG: closing gerda before leistungskarte images are added results in crash
            tlGerda.to(truemmerfrau.position, {
                duration: 3,
                ease: 'power3',
                y: pos.y + 3,
                z: pos.z - 1,
                onComplete: function () {
                    position.copy(truemmerfrau.position)
                    position.x += 0.4
                    position.z += 0.3

                    leistungskarte.visible = false
                    gerdaBlumen.visible = false
                    quittungsmarke.visible = false

                    leistungskarte.position.copy(position)
                    gerdaBlumen.position.copy(position)
                    quittungsmarke.position.copy(position)

                    leistungskarte.visible = true
                    gerdaBlumen.visible = true
                    quittungsmarke.visible = true

                    pos.copy(leistungskarte.position)
                    scale.copy(leistungskarte.scale)
                    tlGerda.to(leistungskarte.position, {
                        duration: 2,
                        ease: 'power3',
                        x: pos.x - 2.5,
                        y: pos.y - 0.0,
                        z: pos.z - 1,
                        //onComplete: function(){scale.copy(gerdaBlumen.scale)}
                    }),
                        '<'
                    tlGerda.to(leistungskarte.scale, {
                        duration: 1,
                        ease: 'power3',
                        x: scale.x * 4.0,
                        y: scale.y * 4.0,
                    }),
                        '<'
                    tlGerda.to(quittungsmarke.position, {
                        duration: 2,
                        ease: 'power3',
                        x: pos.x - 1,
                        y: pos.y + 2.5,
                        z: pos.z - 1.1,
                    }),
                        '<'
                    tlGerda.to(quittungsmarke.scale, {
                        duration: 1,
                        ease: 'power3',
                        x: scale.x * 4.0,
                        y: scale.y * 4.0,
                    }),
                        '<'
                    tlGerda.to(gerdaBlumen.position, {
                        duration: 2,
                        ease: 'power3',
                        x: pos.x + 1.5,
                        y: pos.y + 1,
                        z: pos.z - 1,
                    }),
                        '<'
                    tlGerda.to(gerdaBlumen.scale, {
                        duration: 1,
                        ease: 'power3',
                        x: scale.x * 4.0,
                        y: scale.y * 4.0,
                    }),
                        '<'
                },
                onStart: function () {
                    leistungskarte.visible = false
                    gerdaBlumen.visible = false
                    quittungsmarke.visible = false
                },
            })

            gerdaHidden = false
        } else {
            tlGerda.timeScale(10).reverse()

            //tl.resume();
        }
    }

    this.showFlag = function (show) {
        if (show) {
            flagge_haeger = scope.collageParent.getObjectByName('flagge_haeger')

            let pos = new THREE.Vector3()
            pos.copy(flagge_haeger.position)

            tlFlag.to(flagge_haeger.position, {
                duration: 2,
                ease: 'power3',
                x: pos.x - 2,
                y: pos.y + 1.3,
            })

            let scale = new THREE.Vector3()
            scale.copy(flagge_haeger.scale)

            tlFlag.to(flagge_haeger.scale, {
                duration: 2,
                ease: 'power3',
                x: scale.x * 2.0,
                y: scale.y * 2.0,
            }),
                '>'
        } else {
            tlFlag.reverse()
        }
    }

    this.showWohnung = function (show) {
        if (show) {
            console.log('show wohnung')
            sw_portraet = STRAUSSBERGER.getObjectByName('sw_portraet')
            let pos = new THREE.Vector3()
            pos.copy(sw_portraet.position)

            let endLevel = pos.z + 2.0

            //let scale = new THREE.Vector3();
            let timeStamps = [16, 45, 71, 86, 95, 105, 112, 126, 131, 146, 151, 156, 159, 170]
            //let timeStamps = [5,15,20,25,33,40,46,53,60,65,72,80,85,90,95,100,105,110,115];

            const transition_left_pos = {
                duration: 2,
                ease: 'power1',
                x: pos.x + 1.0,
                y: pos.y + 1.7,
                z: pos.z - 0.5,
            }

            let transition_left_pos_hochformat = JSON.parse(JSON.stringify(transition_left_pos))
            transition_left_pos_hochformat.x = pos.x + 1.2
            //transition_left_pos_hochformat.y = pos.y + 1.5

            const transition_right_pos = {
                duration: 2,
                ease: 'power3',
                x: pos.x - 1.0,
                y: pos.y + 1.7,
                z: pos.z - 0.5,
            }

            let transition_right_pos_hochformat = JSON.parse(JSON.stringify(transition_right_pos))
            transition_right_pos_hochformat.x = pos.x - 1.2
            //transition_right_pos_hochformat.y = pos.y + 1.5

            const transition_scale_smaller = {
                duration: 2,
                ease: 'power1',
                x: 1.0,
                y: 1.0,
            }

            let transition_scale_smaller_hochformat = JSON.parse(
                JSON.stringify(transition_scale_smaller)
            )
            transition_scale_smaller_hochformat.x = 0.5
            transition_scale_smaller_hochformat.y = 0.5

            const transition_scale_bigger = {
                duration: 2,
                ease: 'power1',
                x: 2.2,
                y: 2.2,
            }

            let transition_scale_bigger_hochformat = JSON.parse(
                JSON.stringify(transition_scale_bigger)
            )
            transition_scale_bigger_hochformat.x = 1.5
            transition_scale_bigger_hochformat.y = 1.5

            let index = 0

            tlWohnung = gsap.timeline({ paused: true })

            tlWohnung.to(sw_portraet.position, {
                duration: 3,
                ease: 'power1',
                z: pos.z - 0.3,
                y: pos.y + 1.5,
                onStart: function () {
                    // position.copy(sw_portraet.position);
                    // position.x += 0.4;
                    // position.z += 0.5;

                    sw_pflanzen.visible = false
                    sw_mann.visible = false
                    sw_sitzecke.visible = false
                    sw_glastisch.visible = false
                    sw_bilderwand.visible = false
                    sw_schreibtisch.visible = false
                    sw_sofaecke.visible = false
                    sw_sessel.visible = false
                    sw_bett.visible = false
                    sw_kommode.visible = false
                    sw_statue.visible = false
                    sw_vase.visible = false
                    sw_spiegel.visible = false
                    sw_mietvertrag.visible = false
                },
                onComplete: function () {
                    //pos.copy(sw_pflanzen.position);

                    sw_pflanzen.visible = true
                    sw_mann.visible = true
                    sw_glastisch.visible = true
                    sw_sitzecke.visible = true
                    sw_bilderwand.visible = true
                    sw_schreibtisch.visible = true
                    sw_sofaecke.visible = true
                    sw_sessel.visible = true
                    sw_bett.visible = true
                    sw_kommode.visible = true
                    sw_statue.visible = true
                    sw_vase.visible = true
                    sw_spiegel.visible = true
                    sw_mietvertrag.visible = true
                },
            })

            tlWohnung.to(sw_pflanzen.position, transition_right_pos, timeStamps[index])
            tlWohnung.to(sw_pflanzen.scale, transition_scale_bigger, timeStamps[index])

            index++

            tlWohnung.to(sw_pflanzen.scale, transition_scale_smaller, timeStamps[index])
            tlWohnung.to(sw_mann.position, transition_left_pos, timeStamps[index])
            tlWohnung.to(sw_mann.scale, transition_scale_bigger, timeStamps[index])

            index++

            tlWohnung.to(sw_mann.scale, transition_scale_smaller, timeStamps[index])
            tlWohnung.to(sw_glastisch.position, transition_right_pos_hochformat, timeStamps[index])
            tlWohnung.to(sw_glastisch.scale, transition_scale_bigger_hochformat, timeStamps[index])

            tlWohnung.to(
                sw_pflanzen.position,
                {
                    duration: 0.5,
                    z: endLevel,
                    onComplete: () => {
                        sw_pflanzen.visible = false
                    },
                },
                timeStamps[index]
            )

            index++

            tlWohnung.to(sw_glastisch.scale, transition_scale_smaller_hochformat, timeStamps[index])
            tlWohnung.to(sw_sitzecke.position, transition_left_pos, timeStamps[index])
            tlWohnung.to(sw_sitzecke.scale, transition_scale_bigger, timeStamps[index])

            tlWohnung.to(
                sw_mann.position,
                {
                    duration: 0.5,
                    z: endLevel,
                    onComplete: () => {
                        sw_mann.visible = false
                    },
                },
                timeStamps[index]
            )

            index++

            tlWohnung.to(sw_sitzecke.scale, transition_scale_smaller, timeStamps[index])
            tlWohnung.to(sw_bilderwand.position, transition_right_pos_hochformat, timeStamps[index])
            tlWohnung.to(sw_bilderwand.scale, transition_scale_bigger_hochformat, timeStamps[index])

            tlWohnung.to(
                sw_glastisch.position,
                {
                    duration: 0.5,
                    z: endLevel,
                    onComplete: () => {
                        sw_glastisch.visible = false
                    },
                },
                timeStamps[index]
            )

            index++

            tlWohnung.to(
                sw_bilderwand.scale,
                transition_scale_smaller_hochformat,
                timeStamps[index]
            )
            tlWohnung.to(
                sw_schreibtisch.position,
                transition_left_pos_hochformat,
                timeStamps[index]
            )
            tlWohnung.to(
                sw_schreibtisch.scale,
                transition_scale_bigger_hochformat,
                timeStamps[index]
            )

            tlWohnung.to(
                sw_sitzecke.position,
                {
                    duration: 0.5,
                    z: endLevel,
                    onComplete: () => {
                        sw_sitzecke.visible = false
                    },
                },
                timeStamps[index]
            )

            index++

            tlWohnung.to(
                sw_schreibtisch.scale,
                transition_scale_smaller_hochformat,
                timeStamps[index]
            )
            tlWohnung.to(sw_sofaecke.position, transition_right_pos, timeStamps[index])
            tlWohnung.to(sw_sofaecke.scale, transition_scale_bigger, timeStamps[index])

            tlWohnung.to(
                sw_bilderwand.position,
                {
                    duration: 0.5,
                    z: endLevel,
                    onComplete: () => {
                        sw_bilderwand.visible = false
                    },
                },
                timeStamps[index]
            )

            index++

            tlWohnung.to(sw_sofaecke.scale, transition_scale_smaller, timeStamps[index])
            tlWohnung.to(sw_sessel.position, transition_left_pos, timeStamps[index])
            tlWohnung.to(sw_sessel.scale, transition_scale_bigger, timeStamps[index])

            tlWohnung.to(
                sw_schreibtisch.position,
                {
                    duration: 0.5,
                    z: endLevel,
                    onComplete: () => {
                        sw_schreibtisch.visible = false
                    },
                },
                timeStamps[index]
            )

            index++

            tlWohnung.to(sw_sessel.scale, transition_scale_smaller, timeStamps[index])
            tlWohnung.to(sw_bett.position, transition_right_pos, timeStamps[index])
            tlWohnung.to(sw_bett.scale, transition_scale_bigger, timeStamps[index])

            tlWohnung.to(
                sw_sofaecke.position,
                {
                    duration: 0.5,
                    z: endLevel,
                    onComplete: () => {
                        sw_sofaecke.visible = false
                    },
                },
                timeStamps[index]
            )

            index++

            tlWohnung.to(sw_bett.scale, transition_scale_smaller, timeStamps[index])
            tlWohnung.to(sw_kommode.position, transition_left_pos_hochformat, timeStamps[index])
            tlWohnung.to(sw_kommode.scale, transition_scale_bigger_hochformat, timeStamps[index])

            tlWohnung.to(
                sw_sessel.position,
                {
                    duration: 0.5,
                    z: endLevel,
                    onComplete: () => {
                        sw_sessel.visible = false
                    },
                },
                timeStamps[index]
            )

            index++

            tlWohnung.to(sw_kommode.scale, transition_scale_smaller_hochformat, timeStamps[index])
            tlWohnung.to(sw_statue.position, transition_right_pos, timeStamps[index])
            tlWohnung.to(sw_statue.scale, transition_scale_bigger, timeStamps[index])

            tlWohnung.to(
                sw_bett.position,
                {
                    duration: 0.5,
                    z: endLevel,
                    onComplete: () => {
                        sw_bett.visible = false
                    },
                },
                timeStamps[index]
            )

            index++

            tlWohnung.to(sw_statue.scale, transition_scale_smaller, timeStamps[index])
            tlWohnung.to(sw_vase.position, transition_left_pos, timeStamps[index])
            tlWohnung.to(sw_vase.scale, transition_scale_bigger, timeStamps[index])

            tlWohnung.to(
                sw_kommode.position,
                {
                    duration: 0.5,
                    z: endLevel,
                    onComplete: () => {
                        sw_kommode.visible = false
                    },
                },
                timeStamps[index]
            )

            index++

            tlWohnung.to(sw_vase.scale, transition_scale_smaller, timeStamps[index])
            tlWohnung.to(sw_spiegel.position, transition_right_pos, timeStamps[index])
            tlWohnung.to(sw_spiegel.scale, transition_scale_bigger, timeStamps[index])

            tlWohnung.to(
                sw_statue.position,
                {
                    duration: 0.5,
                    z: endLevel,
                    onComplete: () => {
                        sw_statue.visible = false
                    },
                },
                timeStamps[index]
            )

            index++

            tlWohnung.to(sw_spiegel.scale, transition_scale_smaller, timeStamps[index])
            tlWohnung.to(sw_mietvertrag.position, transition_left_pos, timeStamps[index])
            tlWohnung.to(sw_mietvertrag.scale, transition_scale_bigger, timeStamps[index])

            tlWohnung.to(
                sw_vase.position,
                {
                    duration: 0.5,
                    z: endLevel,
                    onComplete: () => {
                        sw_vase.visible = false
                    },
                },
                timeStamps[index]
            )

            index++

            let audio = document.getElementById('audio')

            if (audio) {
                audio.onplay = function () {
                    tlWohnung.play()
                }

                audio.onpause = function () {
                    tlWohnung.pause()
                }

                audio.onseeked = function () {
                    tlWohnung.time(audio.currentTime)
                }

                audio.ontimeupdate = function () {
                    tlWohnung.time(audio.currentTime)
                }
            }

            wohnungHidden = false
        } else {
            tlWohnung.duration(3).reverse()

            let audio = document.getElementById('audio')

            audio.onplay = function () {}

            audio.onpause = function () {}

            audio.onseeked = function () {}

            audio.ontimeupdate = function () {}
        }
    }

    this.standTruemmerfrau = function (stand) {
        if (stand) {
            truemmerfrau = scope.collageParent.getObjectByName('truemmerfrau_bueckend')
            let pos = new THREE.Vector3()
            pos.copy(truemmerfrau.position)

            truemmerfrau.visible = false

            pos.y = 0
            addImage('/img/truemmerfrau.png', pos, 2.0, 'truemmerfrau', {
                group: GERDA_TRUEMMER,
                renderOrder: 0.6,
            })
            pos.x += 1
            pos.z -= 0.21
            addImage('/img/gerda_truemmer.png', pos, 2.0, 'gerda_truemmer', {
                group: GERDA_TRUEMMER,
                renderOrder: 0.72,
                isTrigger: true,
            })
        }
    }

    this.showMikrokosmos = function (show) {
        if (show) {
            flagge_israel = scope.collageParent.getObjectByName('flagge_israel')
            let pos = new THREE.Vector3()
            pos.copy(flagge_israel.position)

            let scale = new THREE.Vector3()

            tlMikrokosmos = gsap.timeline({ onReverseComplete: finishReverse })

            //TODO: BUG: closing gerda before leistungskarte images are added results in crash
            tlMikrokosmos.to(flagge_israel.position, {
                duration: 1,
                ease: 'power3',
                y: pos.y + 0,
                z: pos.z - 0,
                onComplete: function () {
                    position.copy(flagge_israel.position)
                    position.x += 0.4
                    position.z += 0.3

                    flagge_pride.visible = false

                    flagge_pride.position.copy(position)
                    flagge_pride.visible = true

                    pos.copy(flagge_pride.position)
                    scale.copy(flagge_pride.scale)
                    tlMikrokosmos.to(flagge_pride.position, {
                        duration: 2,
                        ease: 'power3',
                        x: pos.x + 2.5,
                        y: pos.y + 1,
                        z: pos.z - 1,
                    }),
                        '<'
                    tlMikrokosmos.to(flagge_pride.scale, {
                        duration: 1,
                        ease: 'power3',
                        x: scale.x * 4.0,
                        y: scale.y * 4.0,
                    }),
                        '<'
                },
                onStart: function () {
                    flagge_pride.visible = false
                },
            })

            mikrokosmosHidden = false
        } else {
            tlMikrokosmos.timeScale(10).reverse()
        }
    }
}

export { Collage }
