<template>
    <div id="app">
        <div id="header">
            <router-link to="/">Karl Marx Allee</router-link>
            <router-link to="/about"> </router-link>
        </div>
        <router-view />
    </div>
</template>

<style lang="scss">
@import "./assets/_config.scss";
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    //height: 100%;
    //height: -webkit-fill-available;
    touch-action: none;
    &.sketch {
        overflow: hidden;
    }
}

html{
    height: -webkit-fill-available;
}

#app {
    font-family: Oswald, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    position: relative;
    color: $dark;
    padding-bottom: env(safe-area-inset-bottom)
}

#header {
    color: $light;
    margin: 0 auto;
    padding-top: 1rem;
    padding-left: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    z-index: 2;
    font-size: 3rem;

    //disabke text selection
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; // Non-prefixed version, currently

    a {
        color: $lightmiddle;
        text-decoration: none;
        align-self: center;

        &.router-link-exact-active {
            color: $light;
        }
    }
}

@include media-s{
    #header{
        height: 7vh;
        font-size: 2rem;
        padding-top: 1rem;
        padding-left: 1rem;
    }
}

@include media-md{
    #header{
        height: 7vh;
        font-size: 2.5rem;
        padding-top: 0.5rem;
        padding-left: 1.5rem;
    }
}

@include media-lg{
    #header {
        font-size:2.5rem;
    }
}

.home {
    margin: 0;
    text-align: center;
    color: $lightmiddle;
    position: relative;
    z-index:1;
}
</style>
