<template>
    <transition @before-enter="beforeEnterMedia" @enter="enterMedia" @leave="leaveMedia">
        <div>
            <div v-show="playMedia" id="videoContainer" @mousemove="mouseMove()" @click="mouseMove()"
                @touchstart="mouseMove()" :style="{ height: containerHeight }">
                <video id="video" :src="source" v-if="isVideo && hasMedia" ref="video" :volume="volume"
                    @play="changeButtonState('playpause')" @error="console.log('error video')"
                    @stalled="console.log('stalled')" @loadedmetadata="loadedMetaData()" @loadeddata="loadedData()"
                    @pause="changeButtonState('playpause')" @volumechange="checkVolume()"
                    @timeupdate="updateProgressBar()"></video>
                <audio id="audio" :src="source" v-if="!isVideo && hasMedia" preload="auto" ref="audio" :volume="volume"
                    @play="changeButtonState('playpause')" @loadstart="loadStart()" @loadedmetadata="loadedMetaData()"
                    @loadeddata="loadedData()" @pause="changeButtonState('playpause')" @volumechange="checkVolume()"
                    @timeupdate="updateProgressBar()"></audio>

                <div id="controlbar" v-show="showControlBar">
                    <div id="description" ref="description" @click="setProgress" @touchstart="setProgress">
                        <div class="person">{{ person }}</div>
                        <div class="description_intro">{{ description_intro }}</div>
                    </div>
                    <div id="video-controls" class="controls" ref="video-controls" v-show="hasMedia">
                        <div class="progress">
                            <progress id="progress" value="0" min="0" ref="progress" @click="setProgress($event)"
                                @touchstart="setProgress($event)">
                                <!-- <span id="progress-bar" ref="progress-bar"></span> -->
                            </progress>
                        </div>
                        <div id="buttons">
                            <div class="durationContainer">
                                <div class="duration">{{ currentTime }} /</div>
                                <div class="duration">{{ duration }}</div>
                            </div>
                            <ph-pause id="playpause" type="button" ref="playpause" v-show="play" @click="playpause"
                                @touchstart="playpause" />
                            <ph-play id="playpause" type="button" ref="playpause" v-show="!play" @click="playpause"
                                @touchstart="playpause" />
                            <div class="volContainer">
                                <ph-speaker-high id="mute" type="button" v-show="!muted" ref="mute" @click="mute"
                                    @touchstart="mute" @mouseover="showVolControls = true" />
                                <ph-speaker-x id="mute" type="button" v-show="muted" ref="mute" @click="mute"
                                    @touchstart="mute" @mouseover="showVolControls = true" />
                                <input v-show="showVolControls" type="range" min="0" max="10" :value="volumeRange"
                                    id="volControls" ref="volControls" @mouseleave="showVolControls = false"
                                    @click="setVolume($event)" />
                            </div>
                            <!-- <button id="mute" type="button" data-state="mute" ref="mute" @click="mute">Mute/Unmute</button> -->
                            <!-- <button id="volinc" type="button" data-state="volup" ref="volinc" @click="this.alterVolume('+')">Vol+</button>
                            <button id="voldec" type="button" data-state="voldown" ref="voldec" @click="this.alterVolume('-')">Vol-</button> -->
                        </div>
                        <!-- <button id="fs" type="button" data-state="go-fullscreen" ref="fs">Fullscreen</button> -->
                    </div>
                </div>
                <ph-x id="close" type="button" @click="close" @touchstart="close"
                    v-show="showControlBar && hasCloseButton" />
            </div>
            <div v-show="(isVideo || endAudio) && showOverlay" class="outside"></div>
        </div>
    </transition>
</template>

<script>
import { Vector2 } from 'three'
import { gsap } from 'gsap'
//import NavOverlay from "./NavOverlay.vue"
//import VideoControls from "./VideoControls.vue";

import { PhSpeakerHigh, PhPause, PhPlay, PhSpeakerX, PhX } from '@phosphor-icons/vue'

let timer

export default {
    data() {
        return {
            play: false,
            muted: false,
            mediaLoaded: false,
            duration: 0,
            currentTime: 0,
            showControlBar: true,
            closed: false,
            showVolControls: false,
            volume: 0.5,
            iconSize: 40,
        }
    },
    provide() {
        return {
            color: '#D4CCC8',
            size: this.iconSize,
            //height: "50%",
            mirrored: false,
        }
    },
    components: {
        //NavOverlay,
        //Phospor Icons
        PhSpeakerHigh,
        PhPause,
        PhPlay,
        PhSpeakerX,
        PhX,
        //VideoControls
    },
    props: {
        playMedia: {
            type: Boolean,
        },
        source: {
            type: String,
        },
        isVideo: {
            type: Boolean,
        },
        windowSize: {
            type: Vector2,
        },
        person: {
            type: String,
        },
        description_intro: {
            type: String,
        },
        showOverlay: {
            type: Boolean,
        },
        endAudio: {
            type: Boolean,
        },
        hasCloseButton: {
            default: true,
            type: Boolean,
        },
        isTutorial: {
            default: false,
            type: Boolean,
        },
        hasMedia: {
            default: true,
            type: Boolean,
        },
    },
    watch: {
        windowSize(newSize) {
            if (newSize.x < 992) {
                //lg breakpoint of config js
                this.iconSize = 50
            }
            this.iconSize = 30
        },
    },
    computed: {
        x() {
            return this.windowSize.x * 0.7
        },
        volumeRange: function () {
            return this.volume * 10
        },
        containerHeight: function () {
            if (this.isVideo) {
                return '100vh'
            } else {
                //return "8vh";
                return '100vh'
            }
        },
    },
    emits: ['media-closed', 'intro-task-done'],
    //inject: ["person","description_intro","description_outro","showOverlay"],
    methods: {
        beforeEnterMedia: function () {
            if (!this.playMedia) return
            let media = 'audio'
            if (this.isVideo) {
                media = 'video'
            }

            this.$refs[media].opacity = 1.0
            this.$refs[media].load()
            this.$refs[media].play()
            this.closed = false
        },
        enterMedia: function () {
            if (!this.playMedia) return
            if (this.isVideo) {
                this.updateProgressBar()
                gsap.to(this.$refs['video'], {
                    duration: 1,
                    opacity: 1,
                })
            }
            this.play = false
            this.$refs['description'].opacity = 0
            gsap.set(this.$refs['description'], {
                duration: 0.5,
                opacity: 1,
            })
            this.mouseMove() //so that we can see the controlbar a bit
        },
        leaveMedia: function (el, done) {
            if (!this.closed) return
            gsap.to(el, {
                delay: 10,
                duration: 1,
                opacity: 0,
                onComplete: done,
            })
        },
        mouseMove: function () {
            this.showControlBar = true
            clearTimeout(timer)
            timer = setTimeout(() => {
                this.showControlBar = false
            }, 4000)
        },
        //CONTROL METHODS
        close: function () {
            this.closed = true
            this.play = false
            if (this.hasMedia) {
                if (this.isVideo) {
                    console.log('clean video memory')
                    this.$refs['video'].pause()
                    this.$refs['video'].src = ''
                    this.$refs['video'].load()
                    gsap.to(this.$refs['video'], {
                        duration: 1,
                        opacity: 0,
                        onComplete: this.onClose,
                    })
                } else {
                    this.$refs['audio'].pause()
                    this.$refs['audio'].src = ''
                    this.$refs['audio'].load()
                    this.onClose()
                }
            } else {
                this.onClose()
            }
        },
        loadStart: function () {
            console.log('start loading', this.source)
        },
        loadedData: function () {
            this.mediaLoaded = true
            let media = 'audio'
            if (this.isVideo) {
                media = 'video'
            }
            this.duration = this.secondsToMinutes(this.$refs[media].duration)

            this.$refs['progress'].setAttribute(
                'max',
                Math.floor((this.$refs[media].currentTime / this.$refs[media].duration) * 100) +
                '%'.duration
            )
            this.$refs['progress'].setAttribute('value', 0)
            this.playpause();
        },
        loadedMetaData: function () {
            console.log('loaded metadata')
            // let media="audio"
            // if(this.isVideo){
            //     media="video"
            // }
            // If the browser doesn't support the progress element, set its state for some different styling
            // let supportsProgress = (document.createElement('progress').max !== undefined);
            // if (!supportsProgress) this.$refs["progress"].setAttribute('data-state', 'fake');

            //this.$refs["progress"].setAttribute('max',  Math.floor((this.$refs[media].currentTime / this.$refs[media].duration) * 100) + '%'.duration);
        },
        onClose: function () {
            this.$emit('media-closed')
            this.mediaLoaded = false
            this.duration = 0
            this.currentTime = 0
        },
        // updateProgressBar: function(e){
        //     // As the video is playing, update the progress bar
        //     let video = e.target
        //     let progress = this.$refs["progress"]
        //     // For mobile browsers, ensure that the progress element's max attribute is set
        //     if (!progress.getAttribute('max')) {
        //             progress.setAttribute('max', video.duration);
        //     }
        //     progress.value = video.currentTime;
        //     let width =  Math.floor((video.currentTime / video.duration) * 100) + '%'
        //     this.$refs["progress-bar"].style.width = width;
        // },
        updateProgressBar: function () {
            let media = this.$refs['audio']
            if (this.isVideo) {
                media = this.$refs['video']
            }
            // As the video is playing, update the progress bar
            let progress = this.$refs['progress']

            // For mobile browsers, ensure that the progress element's max attribute is set
            if (!progress.getAttribute('max')) {
                progress.setAttribute('max', this.duration)
                this.duration = media.duration
            }
            this.currentTime = this.secondsToMinutes(media.currentTime)
            progress.value = media.currentTime
            let width = Math.floor((media.currentTime / media.duration) * 100) + '%'
            this.$refs['progress'].style.width = width
        },
        setProgress: function (e) {
            let media = 'audio'
            if (this.isVideo) {
                media = 'video'
            }

            let target = e.target
            let pos =
                (e.pageX - (target.offsetLeft + target.offsetParent.offsetLeft)) /
                target.offsetWidth
            if (typeof pos === 'number' && typeof this.$refs[media].duration === 'number') {
                this.$refs[media].currentTime = pos * this.$refs[media].duration
            }
        },
        playpause: function () {
            let media = 'audio'
            console.log('pressed play!')
            if (this.isVideo) {
                media = 'video'
            }
            if (this.$refs[media].paused || this.$refs[media].ended) {
                this.$refs[media].play()
            } else {
                this.$refs[media].pause()
                if (this.isTutorial) {
                    this.$emit('intro-task-done')
                }
            }
        },
        mute: function () {
            let media = 'audio'
            if (this.isVideo) {
                media = 'video'
            }
            this.$refs[media].muted = !this.$refs[media].muted
            this.muted = !this.muted

            if (this.muted) {
                this.volume = 0
                this.showVolControls = false
            } else {
                this.volume = 0.5
                this.showVolControls = true
            }
            //this.changeButtonState('mute');
        },
        setVolume: function (e) {
            this.volume = e.target.value / 10

            //CHECK IF MUTE
            let media = 'audio'
            if (this.isVideo) {
                media = 'video'
            }
            //this.$refs[media].volume = this.volume;

            if (this.volume <= 0) {
                this.$refs[media].muted = true
                this.muted = true
            } else {
                this.$refs[media].muted = false
                this.muted = false
            }
        },
        // Changes the button state of certain button's so the correct visuals can be displayed with CSS
        changeButtonState: function (type) {
            // Play/Pause button
            if (type == 'playpause') {
                this.play = !this.play

                // if (this.$refs["video"].ended){
                //     this.play = true;
                // }
                //     if (this.$refs["video"].paused || this.$refs["video"].ended) {
                //         this.$refs["playpause"].setAttribute('data-state', 'play');
                //     }
                //     else {
                //         this.$refs["playpause"].setAttribute('data-state', 'pause');
                //     }
            }
            // Mute button
            else if (type == 'mute') {
                //this.muted = !this.muted
            }
        },
        // Check the volume
        checkVolume: function (dir) {
            let media = 'audio'
            if (this.isVideo) {
                media = 'video'
            }
            if (dir) {
                let currentVolume = Math.floor(this.$refs[media].volume * 10) / 10
                if (dir === '+') {
                    if (currentVolume < 1) this.$refs[media].volume += 0.1
                } else if (dir === '-') {
                    if (currentVolume > 0) this.$refs[media].volume -= 0.1
                }
                // If the volume has been turned off, also set it as muted
                // Note: can only do this with the custom control set as when the 'volumechange' event is raised, there is no way to know if it was via a volume or a mute change
                if (currentVolume <= 0) {
                    this.$refs[media].muted = true
                    this.muted = true
                } else {
                    this.$refs[media].muted = false
                    this.muted = false
                }
            }
            //this.changeButtonState('mute');
        },
        secondsToMinutes: function (seconds) {
            return Math.floor(seconds / 60) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2)
        },
    },
}
</script>

<style lang="scss" scoped>
@import './assets/_config.scss';

#videoContainer {
    position: absolute;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    bottom: 0;
}

#video {
    position: absolute;
    top: 7%; //da der banner 7vh hoch ist
    min-width: 100%;
    min-height: 86%; // 100 - 2*7
    width: auto;
    height: auto;
    max-height: 86%; // 100 - 2*7
}

#controlbar {
    display: block;
    background: transparent;
    background-color: $darkmiddle;
    width: 100vw;
    height: 7vh;
    position: absolute;
    bottom: 0;
}

@-moz-document url-prefix() {
    #description {
        align-items: last baseline;
    }
}

#description {
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    color: $light;
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 100%;
}

#close {
    @include close-button;
}

.controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    #buttons {
        width: 10%;

        button {
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            border: none;
            cursor: pointer;
            text-indent: -99999px;
            background: transparent;
            background-size: contain;
            background-repeat: no-repeat;
            padding: '0.5rem';
            position: relative;
            //@at-rootwidth:auto;
            //min-width:30px;

            :hover,
            :focus {
                opacity: 0.5;
            }
        }

        #playpause {
            position: fixed;
            top: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
            background-color: $darkmiddle;
        }

        .durationContainer {
            display: flex;
            flex-direction: row;
            font-size: 1rem;
            color: $light;

            .duration {
                padding: 0.25rem;
            }
        }

        .volContainer {
            display: none; //not in mobile
            position: relative;
            flex-direction: column-reverse;
            align-items: center;
        }

        input[type='range'] {
            -webkit-appearance: none;
            outline: none;
            background: $light;
            position: absolute;
            transform: rotate(270deg) translateX(80%);
            transform-origin: center;
            border-radius: 10px;
            height: 10px;

            &::-webkit-slider-thumb,
            &::-moz-range-thumb,
            &::-ms-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: $darkmiddle;
                cursor: pointer;
                outline: none;
                border: none;
            }

            &::-webkit-slider-runnable-track,
            &::-moz-range-track {
                background: $light;
                border-radius: 10px;
                height: 100%;
                width: 100%;
            }

            &::-moz-range-progress {
                background: $middle;
                border-radius: 10px;
                height: 100%;
                width: 100%;
            }

            &::-ms-track {
                width: 100%;
                cursor: pointer;

                /* Hides the slider so custom styles can be added */
                background: transparent;
                border-color: transparent;
                color: transparent;
            }

            &:focus {
                outline: none;
            }
        }
    }

    .progress {
        cursor: pointer;
        width: 90%;
        height: 100%;

        progress {
            -webkit-appearance: none;
            /* Override default look */
            display: block;
            height: 100%;
            border: none;
            overflow: hidden;
            opacity: 0.75;
            color: $dark;
            /* Internet Explorer uses this value as the progress bar's value colour */

            [data-state='fake'] {
                opacity: 0.75;
                height: 30%;
            }

            &::-moz-progress-bar {
                background-color: $dark !important;
            }

            &::-webkit-progress-value {
                background: $dark !important;
            }
        }
    }
}

.outside {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 3;
    background: $dark;
    opacity: 0.8;
}

@include media-s {
    #videoContainer {
        min-height: -webkit-fill-available;
    }

    #description {
        align-items: center;
        padding: 0.5rem;

        .person {
            font-size: 1.25rem;
            padding-right: 0.1rem;
        }

        .description_intro,
        .description_outro {
            font-size: 1rem;
            padding-left: 0.1rem;
            padding-right: 0.1rem;
        }
    }

    #controlbar {
        height: 10vh;
    }

    .controls {
        height: 10vh;

        #buttons {
            .durationContainer {
                font-size: 0.75rem;
            }
        }
    }

    #video {
        min-height: 100%;
        max-height: 100%;
        top: 0;
    }
}

@include media-md {
    #videoContainer {
        min-height: -webkit-fill-available;
    }

    @-moz-document url-prefix() {
        #description {
            align-items: last baseline;
        }
    }
}

@include media-lg {
    #controlbar {
        height: 7vh;
    }

    #description {
        width: 75%;
        padding-left: 1.5rem;
        align-items: baseline;
        padding: 0;

        .person {
            font-size: 1.5rem;
            padding: 0.2rem 0.5rem;
        }

        .description_intro,
        .description_outro {
            font-size: 1.2rem;
            padding: 0.2rem 0.5rem;
        }
    }

    #close {
        width: 5vh;
        height: 5vh;
        top: 0;
        right: 0;
        transform: none;
        margin: 0.5rem;
    }

    #video {
        min-height: 86%;
        max-height: 86%;
        top: 7%;
    }

    .controls {
        height: 7vh;

        #buttons {
            width: 25%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            position: relative;

            font-size: 1rem;

            .durationContainer {
                font-size: 1.25rem;
            }

            .volContainer {
                display: flex;
            }

            #playpause {
                position: relative;
                top: 0;
                left: 0;
                transform: none;
                background-color: none;
            }
        }

        .progress {
            width: 75%;
        }
    }
}

/* fullscreen */
html:-ms-fullscreen {
    width: 100%;
}

:-webkit-full-screen {
    background-color: transparent;
}

video:-webkit-full-screen+.controls {
    background: #ccc;
    /* required for Chrome which doesn't heed the transparent value set above */
}

video:-webkit-full-screen+.controls progress {
    margin-top: 0.5rem;
}

/* hide controls on fullscreen with WebKit */
figure[data-fullscreen='true'] video::-webkit-media-controls {
    display: none !important;
}

figure[data-fullscreen='true'] {
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    max-height: 100%;
}

figure[data-fullscreen='true'] video {
    height: auto;
}

figure[data-fullscreen='true'] figcaption {
    display: none;
}

figure[data-fullscreen='true'] .controls {
    position: absolute;
    bottom: 2%;
    width: 100%;
    z-index: 2147483647;
}

figure[data-fullscreen='true'] .controls li {
    width: 5%;
}

figure[data-fullscreen='true'] .controls .progress {
    width: 68%;
}
</style>
