<template>
    <div id="scene">
        <transition @enter="enterOutro" @leave="closeOutro">
            <div v-show="outro" class="description_outro" @click="outro = false" @touchstart="outro = false">
                {{ description_outro }}
            </div>
        </transition>
        <MediaSnippet v-if="showOverlay" v-bind="overlayProps" :source="mediaSource" :showOverlay="showOverlay"
            :playMedia="playMedia" :isVideo="isVideo" :hasMedia="hasMedia" :windowSize="windowSize" :endAudio="endAudio"
            @media-closed="closeMedia">
        </MediaSnippet>
        <!-- <div id="gui"></div> -->
        <video src="" id="videoTexture" crossOrigin="anonymous" playsinline>
            <source src="">
        </video>
        <div class="zoom" @click="overview" v-show="!showOverlay"> <ph-map-trifold :size="32" :color="zoomColor" />
        </div>
        <div class="labels" v-show="showOverview">
            <div v-for="person in personDescription" :key="person.id" class="label" :class="{ played: person.played }"
                :id="person.id" :style="{ transform: person.labelPos }">
                {{ person.name }}
            </div>
        </div>
        <div class="zoom_description" v-show="showOverview">
            Übersicht
        </div>
        <div id="container" ref="container">
            <canvas id="canvas" ref="canvas"></canvas>
        </div>
    </div>
</template>

<script>
"use strict";
//VUE
//import {provide,ref,readonly} from "vue";
import MediaSnippet from "./MediaSnippet.vue";
//import NavOverlay from "./NavOverlay.vue";

//LIBRARIES
import * as THREE from "three";
import { gsap } from 'gsap';
//import { PhX } from "phosphor-vue";
//import { GUI } from "three/examples/jsm/libs/dat.gui.module.js";
//import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { PhMapTrifold } from "@phosphor-icons/vue";


//CLASSES
import { MaterialLoader } from "./js/loaders/MaterialLoader.js";
//import {AudioLoader} from './js/loaders/AudioLoader.js';
import { Collage } from "./js/Collage.js";
import { DragControls } from "./js/DragControls.js";
import { DocElementManager } from "./js/DocElementManager.js";
import { Shader } from "./shaders/Shader.js";

//CONSTANTS
//level of camera before watching something
//const DEFAULT_LEVEL = 0;
//level of images of the building
const BUILDING_LEVEL = 12;

const BLOOM_SCENE = 1;

//VARIABLES
let scene, renderer, frame, camera;

let finalComposer, bloomComposer, bloomLayer;
const darkMaterial = new THREE.MeshBasicMaterial({ color: "black" });
const bloomMaterials = {};

let controls;
//let overviewControls;
let overviewCamera;
let docElementManager;

let materials, materialsRevealed;
// const myFov = 50;
// const viewWidth = 100
// const viewHeight = 75
//let audios;
//let guiParameters;
let collage;
let tempV = new THREE.Vector3();

//VUE
export default {
    name: "Scene",
    components: {
        MediaSnippet,
        PhMapTrifold
    },
    data() {
        return {
            mediaSource: "",
            playMedia: false,
            isVideo: false,
            windowSize: new THREE.Vector2(0, 0),
            overlayProps: {
                person: "",
                description_intro: "",
            },
            showOverlay: false,
            showOverview: false,
            endAudio: false,
            hasMedia: true,
            description_outro: "",
            outro: false,
            isMounted: false,
            horizontalFov: 70,
            overviewHorizontalFov: 60,
            personDescription: {
                winnington: {
                    id: "winnington",
                    trigger: "straussbergertrigger",
                    worldPos: new THREE.Vector3(0, 0, 0),
                    labelPos: "",
                    played: false,
                    name: "Frau Winnington",
                    intro: "erzählt über ihre Wohnung am Strausberger Platz",
                    outro: "Ursula Winnington ist mit ihrem Ehemann Alan Winnington 1967 in die Karl-Marx-Allee gezogen. Sie war die berühmteste Kochbuchautorin und eine Koryphäe der Kochkunst in der DDR. Ihr Mann, Alan Winnington, war Buchautor und Journalist, der erst in China lebte, unter anderem über den Korea Krieg berichtete und schließlich wegen seiner politischen Haltung als Kommunist seinen britischen Pass nicht verlängert bekam und 1960 als Staatenloser nach Ost Berlin zog. Die Wohnung am Strausberger Platz haben beide durch einen Wohnungstausch über Bekannte ihrer Freunde bekommen."
                },
                schaben: {
                    id: "schaben",
                    trigger: "schabentrigger",
                    worldPos: new THREE.Vector3(0, 0, 0),
                    labelPos: "",
                    played: false,
                    name: "Schaben",
                    intro: "waren auch in der Karl-Marx Allee zu Hause.",
                    outro: "Viele Altbewohner erzählten vom Schabenproblem. Besonders gerne mochten die Schaben die Mülltonnen nahe dem Broiler Restaurant am Strausberger Platz. Aber auch in den Wohnungen gingen die Schaben gerne ein und aus. Die Ursache, da waren sich alle Bewohner einig, waren die Müllschlucker, die es auf jeder Etage gab. Was anfangs als Innovation gefeiert wurde, wurde später verflucht. Während der Renovierungen in den 90er Jahren wurden die Müllschlucker schließlich zurückgebaut. Seitdem wurde angeblich keine einzige Schabe gesichtet."
                },
                thieme: {
                    id: "thieme",
                    trigger: "herrthiemetrigger",
                    worldPos: new THREE.Vector3(0, 0, 0),
                    labelPos: "",
                    played: false,
                    name: "Herr Thieme",
                    intro: "spricht über seine Kindheit",
                    outro: "Herr Thieme ist in den 1950er Jahren als Kind im Berliner Friedrichshain großgeworden. Während seine alleinerziehende Mutter als Trümmerfrau mithalf, die Straße aufzubauen, war die große Baustelle an der Karl-Marx-Allee für ihn und seine Freunde ein gigantischer Spielplatz. Später wurde er Installateur und arbeitete nach der Wende auch im Westen."
                },
                goldschmidt_truemmer: {
                    id: "goldschmidt_truemmer",
                    trigger: "truemmerfrau_bueckend",
                    worldPos: new THREE.Vector3(0, 0, 0),
                    labelPos: "",
                    name: "Frau Goldschmidt",
                    intro: "erinnert sich an ihre Aufbauarbeit in der Karl-Marx-Allee.",
                    outro: "Frau Goldschmidt ist eine gebürtige Berlinerin, die in Friedrichshain aufgewachsen ist. Obwohl sie ihren Vater im Naziregime verloren hatte, blieb sie nach dem Krieg in der Stadt, denn sie liebte Berlin und konnte sich keinen anderen Ort vorstellen. Sie hat unzählige Aufbaustunden geleistet, um ihr geliebtes Berlin wieder aufzubauen.  Anfangs arbeitete sie zusätzlich bei einem Metzgermeister und später im Schlachthof, wo sie während der Arbeiterproteste im Juni 1953 Überstunden geleistet hatte und dafür eine Auszeichnung bekam."
                },
                goldschmidt_name: {
                    id: "goldschmidt_name",
                    trigger: "briefkasten",
                    worldPos: new THREE.Vector3(0, 0, 0),
                    labelPos: "",
                    played: false,
                    name: "Frau Goldschmidt",
                    intro: "erzählt die Geschichte ihres Familiennamens",
                    outro: "Frau Goldschmidt liebte Berlin und so blieb sie nach dem Krieg und baute die Stadt mit auf, obwohl ihr jüdischer Vater 1940 im KZ Sachsenhausen ermordet wurde. Sie zeigte mir das einzige Foto von ihrem Vater und musste ihre Erzählung abbrechen. Das Foto mochte sie mir nicht zum Fotografieren übergeben. Zu schmerzhaft, zu persönlich. die Erinnerungen tuen noch heute weh. Ein Teil Ihrer Familie ist nach Kanada und Israel ausgewandert. In letzter Zeit wurde sie schon mehrmals wegen ihres Namens über die Klingelanlage belästigt. Sie spürt die zunehmende antisemitische Stimmung und sie beschloß aus Angst, ihren Namen vom Klingelschild abzunehmen."
                },
                mieterbeirat: {
                    id: "mieterbeirat",
                    trigger: "banner_beute",
                    worldPos: new THREE.Vector3(0, 0, 0),
                    labelPos: "",
                    played: false,
                    name: "Der Mieterbeirat",
                    intro: "kämpfte gegen den Verkauf vier Häuser an die Deutsche Wohnen GmbH",
                    outro: "Am 3. November 2018 hat der Mieterbeirat zum ersten Treffen mit den Mietern eingeladen. Danach startete ein durch Mieter und Mieterbeirat organisierter Widerstand gegen den Häuserverkauf an die Deutsche Wohnen, der gut ein halbes Jahr gedauert hat. Die meisten Mieter hatten ausgesprochen Angst. Angst vor Kündigungen, vor Mieterhöhungen, vor schlechter Verwaltung, Angst vor Veränderungen. Ein älterer Mieter hat schnell sein Testament geschrieben, weil er befürchtete, durch den Stress vorzeitig zu sterben."
                },
                demo: {
                    id: "demo",
                    trigger: "demo",
                    name: "Mieter der Karl-Marx-Allee",
                    labelPos: "",
                    played: false,
                    worldPos: new THREE.Vector3(0, 0, 0),
                    intro: "brechen zur großen Berliner Mieterdemo am 6.4.2019 auf",
                    outro: "Die Demo hatte ihren Startpunkt am Alexanderplatz und verlief die Karl-Marx-Allee entlang bis nach Kreuzberg. Eine Stimmung wie auf einem Jahrmarkt, ausgelassen und feierlich. Überall wurde gefilmt. Zu dem Zeitpunkt war noch nicht sicher, wie der gestreckte Erwerb der Gewobag und der Stadt ausgehen würde. Zwei Monate später war klar, dass es geklappt hatte. Die Häuserblöcke C Süd, C Nord und D Nord werden durch einen gestreckten Erwerb an die Gewobag verkauft."
                },
                // dahlmeier:{
                //     id:"dahlmeier",
                //     name: "Frau Barbara Dalheimer",
                //     intro: "überquerte die Karl-Marx-Allee ein letztes Mal",
                //     outro: "Barbara Dalheimer zog in 2004 in die Karl-Marx-Allee, sie liebte orthodoxe Kirchenmusik, sang selbst im Chor, organisierte früher Chorauftritte unbekannter orthodoxer Chöre deutschlandweit, sie war kulturhungrig und fotografier-begeistert und reiste sehr gerne. Ihre Freunde erzählten, dass sie eine wunderschöne Altstimme hatte. Sie kam bei mir gerne im kleinen Atelier vorbei und als sie im Winter sah wie kalt es drinnen war, schenkte sie mir eine Decke, die sie aus Südamerika mitgebracht hatte. Wir hatten noch vor, zusammen ins Kino zu gehen, aber am 26.6. 2019 wurde sie beim Überqueren der Karl-Marx-Allee vom Auto erfasst und aus dem Leben gerissen. Ihre Decke wärmt mich immer noch."
                // },
                haeger: {
                    id: "haeger",
                    trigger: "flagge_haeger",
                    worldPos: new THREE.Vector3(0, 0, 0),
                    labelPos: "",
                    played: false,
                    name: "Frau Häger",
                    intro: "erinnert sich an das Flaggen während der Feierlichkeiten am ersten Mai und wie sie sich dem entziehen konnte.",
                    outro: "Frau Häger hat ihre Wohnung durch Beharrlichkeit bekommen. Bis auf den einen, oder anderen, seien die meisten Nachbarn sehr nett gewesen. Die Mehrheit waren schon Rentner, oder kurz davor. Heute gehört sie mit ihrem Mann zu der älteren Generation und beklagt, dass sie die meisten Nachbarn nicht mehr kennt."
                },
                // pfoertner:{
                //     id:"pfoertner",
                //     name: "Pförtnerloge",
                //     intro: "",
                //     outro: "In den Anfängen der Karl-Marx-Allee saßen in manchen Häusern Pförtner in kleinen Räumen, sie ließen die Leute ein, nahmen Briefe entgegen und sammelten Nachrichten. Ab den 60ern waren die Pförtner verschwunden. Die kleinen Räume waren verlassen, oder wurden anderweitig genutzt. In den 90ern wurden daraus kleine Geschäfte, kleine Büros, oder kleine Ateliers. Vor mir war zum Beispiel ein Raum für Künstler und davor ein kleiner Laden, der auf Straußeneier spezialisiert war."
                // },
                stalin: {
                    id: "stalin",
                    trigger: "stalin",
                    worldPos: new THREE.Vector3(0, 0, 0),
                    labelPos: "",
                    played: false,
                    name: "Stalinstatue",
                    intro: "",
                    outro: "Vom dritten Augutst 1951 bis zum vierzehnten November 1961 stand in der Karl-Marx-Allee ein Stalindenkmal aus Bronze. Überlebensgroß überblickte die Statue die damalige Stalin-Allee, bis sie in einer Nacht und Nebel Aktion auf Anweisung von Oben verschwand und die Allee hieß am nächsten Tag Karl-Marx-Allee. In den 90er Jahren tauchte eines Tages das linke Ohr von Stalin auf. Einer der Arbeiter, die die Statue demontierten hat es als Andenken mitgenommen. Noch heute kann man es im Café Sybille bewundern."
                },
                mikrokosmos: {
                    id: "mikrokosmos",
                    trigger: "flagge_israel",
                    worldPos: new THREE.Vector3(0, 0, 0),
                    labelPos: "",
                    played: false,
                    name: "Mikrokosmos",
                    intro: " - die Welt spiegelt sich als Mikrokosmos in der Karl-Marx-Allee",
                    outro: "Fahnen hingen hier schon immer, auch heute sieht man sie, es sind selten die roten, sowjetischen, oder DDR Fahnen; auch die habe ich hier gesehen, aber meist sind es andere. Nach dem Massaker durch die Hamas in Israel am 7. Oktober 2023 hängt auch eine Israelfahne aus einem Fenster und auf der anderen Seite gegenüber eine Palästinenserfahne, dazwischen liegt die dreispurige Straße wie ein Graben, auch darin spiegelt sich die Welt im Mikrokosmos Karl-Marx-Allee wieder."
                }
            }
        };
    },
    provide: {
        color: "#D4CCC8",
        size: 30,
    },
    methods: {
        preLoadMaterials: function () {
            let materialLoader = new MaterialLoader();
            return materialLoader.init().then(() => {
                materials = materialLoader.getMaterials();
                materialsRevealed = materialLoader.getMaterialsRevealed();
            });
        },
        // preLoadAudio: function(){
        //     let audioLoader = new AudioLoader();
        //     return audioLoader.init()
        //     .then(() => {
        //         audios = audioLoader.getAudios();
        //     })
        // },
        init: function () {
            let container = this.$refs["container"]//document.getElementById("container");

            //SCENE
            scene = new THREE.Scene();
            //scene.background = new THREE.Color(0xffffff);

            //CAMERA
            let ratio = window.innerWidth / window.innerHeight;
            camera = new THREE.PerspectiveCamera(60, ratio, 0.1, 1000);
            camera.position.set(0, 1, 0);
            camera.lookAt(0, 1, BUILDING_LEVEL);

            let horizontalFov = this.horizontalFov;
            camera.fov = (Math.atan(Math.tan(((horizontalFov / 2) * Math.PI) / 180) / camera.aspect) * 2 * 180) / Math.PI;
            scene.add(camera);
            camera.updateProjectionMatrix();

            overviewCamera = new THREE.PerspectiveCamera(60, ratio, 0.1, 1000);
            overviewCamera.position.set(0, 1, -40);
            overviewCamera.lookAt(0, 1, BUILDING_LEVEL);

            overviewCamera.fov = (Math.atan(Math.tan(((this.overviewHorizontalFov / 2) * Math.PI) / 180) / overviewCamera.aspect) * 2 * 180) / Math.PI;
            overviewCamera.updateProjectionMatrix();
            this.showOverview = false;

            //FLASHLIGHT EFFECT
            let ambient = new THREE.AmbientLight(0xffffff, 0.15)
            ambient.name = "ambient"
            scene.add(ambient);

            let flashlight = new THREE.SpotLight(0xffffff);
            flashlight.position.copy(camera.position);
            flashlight.position.z += 10;
            flashlight.angle = Math.PI / 9;
            flashlight.intensity = 0.8;
            flashlight.penumbra = 1.0;
            flashlight.name = "flashlight"
            //flashlight.power = 4000;
            camera.add(flashlight);
            flashlight.target = camera;

            camera.translateX(-22)

            //SOUND
            const listener = new THREE.AudioListener();
            camera.add(listener);

            //GEO
            let collageHandler = new Collage(scene, materials, BUILDING_LEVEL, BLOOM_SCENE);
            collageHandler.init();

            let geo = new THREE.PlaneGeometry(50, 20);
            let material = new THREE.MeshBasicMaterial({
                color: 0x3E3F3D,
                opacity: 0.7,
                transparent: true
            })
            let overlay = new THREE.Mesh(geo, material);
            overlay.rotateX(Math.PI);
            overlay.visible = false;
            scene.add(overlay);

            //LABELS IN MAP
            collage = collageHandler.getCollageParent()

            for (const [id, person] of Object.entries(this.personDescription)) {
                let obj = collage.getObjectByName(person.trigger);
                const tempV = new THREE.Vector3();
                obj.updateWorldMatrix(true, false);
                obj.getWorldPosition(tempV);
                person.worldPos = tempV;
            }

            //BACKGROUND
            const loader = new THREE.TextureLoader();
            loader.load("/img/alex_abend_dark.jpg", function (t) {
                t.minFilter = THREE.LinearFilter;
                scene.background = t;
            });
            //scene.fog = 0.1;

            //RENDERER
            const canvas = this.$refs["canvas"];
            renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, canvas: canvas });
            renderer.setSize(container.clientWidth, container.clientHeight);
            renderer.outputEncoding = THREE.sRGBEncoding;
            renderer.setClearColor(0x000000, 0);
            // renderer.toneMapping = THREE.ReinhardToneMapping;
            //renderer.toneMappingExposure = Math.pow( 0.6, 4.0 );
            //container.appendChild(renderer.domElement);

            //POST PROCESSING
            //credits for post processing: https://github.com/mrdoob/three.js/blob/master/examples/webgl_postprocessing_unreal_bloom_selective.html
            bloomLayer = new THREE.Layers();
            bloomLayer.set(BLOOM_SCENE);

            const renderScene = new RenderPass(scene, camera);

            const bloomPass = new UnrealBloomPass(new THREE.Vector2(container.clientWidth, container.clientHeight), 1.2, 0.9, 0.85); //strength, radius, treshold

            bloomComposer = new EffectComposer(renderer);
            bloomComposer.renderToScreen = false;
            bloomComposer.addPass(renderScene);
            bloomComposer.addPass(bloomPass);

            let shader = Shader;

            const finalPass = new ShaderPass(
                new THREE.ShaderMaterial({
                    uniforms: {
                        baseTexture: { value: null },
                        bloomTexture: { value: bloomComposer.renderTarget2.texture }
                    },
                    vertexShader: shader.vertexShader,
                    fragmentShader: shader.fragmentShader,
                    defines: {}
                }), "baseTexture"
            );
            finalPass.needsSwap = true;

            finalComposer = new EffectComposer(renderer);
            finalComposer.addPass(renderScene);
            finalComposer.addPass(finalPass);

            controls = new DragControls(scene, camera, renderer.domElement);
            controls.connect();

            //DOCUMENTARY ELEMENT MANAGING
            docElementManager = new DocElementManager(
                camera,
                controls,
                renderer.domElement,
                collageHandler,
                overlay,
                materialsRevealed
            );

            //EVENT HANDLING
            this.windowSize = new THREE.Vector2(
                renderer.domElement.innerWidth,
                renderer.domElement.innerHeight
            );

            window.addEventListener("triggerDocElement", (e) =>
                this.triggerDocElement(e)
            );
            window.addEventListener("changeMedia", (e) =>
                this.changeMedia(e)
            );
            window.addEventListener("setPerson", (e) =>
                this.setPerson(e)
            );
            window.addEventListener("closePerson", this.closePerson);

            //prevent long touch
            window.addEventListener('touchstart', this.preventDefault);
        },
        render: function () {
            // render scene with bloom
            scene.traverse(this.darkenNonBloomed);
            bloomComposer.render();
            scene.traverse(this.restoreMaterial);

            // render the entire scene, then render bloom scene on top
            finalComposer.render();
        },
        darkenNonBloomed: function (obj) {
            if (obj.isMesh && bloomLayer.test(obj.layers) === false) {
                bloomMaterials[obj.uuid] = obj.material;
                obj.material = darkMaterial;
            }

        },
        restoreMaterial: function (obj) {
            if (bloomMaterials[obj.uuid]) {
                obj.material = bloomMaterials[obj.uuid];
                delete bloomMaterials[obj.uuid];
            }
        },
        onWindowResize: function () {
            if (!this.isMounted) return;
            //called by documentary now

            const width = window.innerWidth;
            const height = window.innerHeight;

            //camera
            let aspect = width / height
            camera.aspect = aspect;
            overviewCamera.aspect = aspect;

            camera.fov = (Math.atan(Math.tan(((this.horizontalFov / 2) * Math.PI) / 180) / camera.aspect) * 2 * 180) / Math.PI;
            overviewCamera.fov = (Math.atan(Math.tan(((this.overviewHorizontalFov / 2) * Math.PI) / 180) / overviewCamera.aspect) * 2 * 180) / Math.PI;

            camera.updateProjectionMatrix();
            overviewCamera.updateProjectionMatrix();

            //renderer
            renderer.setSize(width, height);
            renderer.outputEncoding = THREE.sRGBEncoding;
            renderer.setClearColor(0x000000, 0);

            //controls
            controls.handleResize(width, height);
            this.updateLabelPos();

            this.windowSize = new THREE.Vector2(
                renderer.domElement.innerWidth,
                renderer.domElement.innerHeight
            );
        },
        triggerDocElement: function (e) {
            if (this.showOverview) return;
            if (this.showOverlay) return;
            docElementManager.trigger(e.detail.name);
        },
        preventDefault: function (e) {
            e.preventDefault();
        },
        changeMedia: function (e) {
            if (e.detail.path === e.mediaSource) return; //if same one already playing
            if (this.showOverlay) return;
            this.mediaSource = e.detail.path;
            this.isVideo = e.detail.isVideo;
            this.hasMedia = e.detail.hasMedia;
            this.playMedia = true;
            this.showOverlay = true;
            console.log(e.detail.path)
            window.removeEventListener('touchstart', this.preventDefault);
        },
        closeMedia: function () {
            if (!this.playMedia) return; //if nothing is playing
            this.playMedia = false;
            this.mediaSource = "";
            this.outro = true;
            docElementManager.close();
            if (!this.isVideo) {
                this.endAudio = true
            }

            //scene.getObjectByName("flashlight").angle += Math.PI/20 //make flashlight radius bigger
        },
        setPerson: function (e) {
            if (e.detail.name === this.overlayProps.name) return;
            let person = this.personDescription[e.detail.name]

            person.played = true;

            this.overlayProps.person = person.name;
            this.overlayProps.description_intro = person.intro;
            this.description_outro = person.outro;
        },
        enterOutro: function (el) {
            if (!this.outro) return;

            gsap.to(el, {
                duration: 0.5,
                opacity: 1
            })
            // gsap.to(el,{
            //     delay:6,
            //     duration:0.5,
            //     opacity:0,
            //     onComplete: () => this.outro = false
            // })
        },
        closeOutro: function (el) {

            docElementManager.closeOverlay();
            this.showOverlay = false;
            this.endAudio = false;
            window.addEventListener('touchstart', this.preventDefault);

            gsap.to(el, {
                duration: 2,
                opacity: 0
            })

        },
        overview: function () {
            this.showOverview = !this.showOverview;

            //controls
            //controls.enabled = !this.showOverview;

            if (this.showOverview) {
                //update render camera
                //overviewCamera.position.copy(camera.position)
                finalComposer.passes[0].camera = overviewCamera;

                scene.getObjectByName("ambient").intensity = 0.3
                this.updateLabelPos();
            }
            else {
                finalComposer.passes[0].camera = camera;
                scene.getObjectByName("ambient").intensity = 0.15
            }
        },
        updateLabelPos: function () {
            //TODO: only when camera moves
            //TODO: only items near camera in normal mode
            if (!this.showOverview) return;

            for (const [id, person] of Object.entries(this.personDescription)) {
                tempV.copy(person.worldPos);
                if (this.showOverview) {
                    tempV.project(overviewCamera)
                }
                else {
                    tempV.project(camera)
                }
                const x = (tempV.x * .5 + .5) * window.innerWidth;
                const y = (tempV.y * -.5 + .5) * window.innerHeight;

                person.labelPos = `translate(-50%, -50%) translate(${x}px,${y}px)`;
                //document.getElementById(person.id).style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
            }
        },
        animate: function () {
            try {
                // if(this.showOverview){
                //     this.updateLabelPos();
                // }
                controls.update();
                //overviewControls.update();
                //TODO: I have no animation here, so only animate on control move? https://threejs.org/manual/#en/rendering-on-demand
                frame = requestAnimationFrame(this.animate);
                this.render();

            } catch (err) {
                //catch animation error, so it is not running endlessly in error
                cancelAnimationFrame(frame);
                console.log("animation error", err);
            }
        },
        startScene: function () {
            Promise.all([this.preLoadMaterials()])
                .then(() => {
                    this.init();
                    this.isMounted = true;
                })
                .then(() => {
                    this.animate();
                    console.log("called first animation loop")
                });

        }
    },
    computed: {
        zoomColor() {
            if (this.showOverview) {
                return "#D4CCC8"
            }
            else {
                return "#868F8C"
            }
        }
    },
    mounted() {
        this.startScene();

    }
};
</script>

<style scoped lang="scss">
@import "./assets/_config.scss";

#scene {
    @include scene;
}

#background {
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: 0;
    position: fixed;
}

#gui {
    position: fixed;
    top: 2px;
    left: 2px;
    z-index: 2;
}

#videoTexture {
    display: none;
}

.outside {
    width: 100vw;
    height: 40vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 3;
    //background: $darkmiddle;
    //opacity: 0.8;
}

.zoom {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 2rem;
    z-index: 5;

    &_description {
        z-index: 5;
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 2rem;
        padding-left: 1.5rem;
        padding-bottom: 2rem;
        color: $light;
    }
}

.labels {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    color: $dark;
    font-size: 1rem;
    padding: 0.5rem;

    .label {
        background-color: $light;

        &.played {
            background-color: $middle;
        }

        &:hover {
            background-color: $middle;
        }
    }
}

.description_outro {

    #close-outro {
        @include close-button;
    }

    z-index:5;
    @include centered-text;

}

@include media-s {

    #scene {
        // min-height: 100vh;
        // min-height: -webkit-fill-available;
        // height: -webkit-fill-available;
    }

    .description_outro {
        font-size: 1rem;
    }
}

@include media-md {
    .description_outro {
        font-size: 1.5rem;

        #close-outro {
            width: 5vh;
            height: 5vh;
        }
    }
}

@include media-xl {
    .description_outro {
        padding: 0 20vw;
    }
}
</style>